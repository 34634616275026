// src/components/Header.jsx
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import "./layout.css";

const Header = ({ isDropdownOpen, toggleDropdown, toggleSidebar }) => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user")); // Get user data from localStorage

  const handleLogout = () => {
    localStorage.removeItem("user"); // Clear user data from localStorage
    navigate("/login");
  };

  return (
    <nav className="bg-white border-gray-200 header-cls">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <div className="flex items-center">
          <svg
            width="28"
            height="28"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={toggleSidebar}
            style={{ cursor: "pointer" }}
          >
            <rect x="2" y="8" width="20" height="2.5" rx="1" fill="#4E4F4E" />
            <path
              d="M2 23C2 22.4477 2.44772 22 3 22H21C21.5523 22 22 22.4477 22 23V23.5C22 24.0523 21.5523 24.5 21 24.5H3C2.44772 24.5 2 24.0523 2 23.5V23Z"
              fill="#4E4F4E"
            />
            <rect x="2" y="15" width="28" height="2.5" rx="1" fill="#4E4F4E" />
          </svg>
          <a href="/" className="ml-5">
            <img
              src="https://crm.fuelifeexplorers.com/crmImages/logo/1680004129Fuelife-Explorers.png"
              className="react h-[50px]"
              alt="React logo"
            />
          </a>
        </div>
        <div className="flex items-center md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
          {/* <div className="relative">
            <button
              type="button"
              className="no-focus-outline flex items-center text-sm bg-white-900 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
            >
              <span className="material-symbols-outlined">
                notifications_unread
              </span>
            </button>
          </div> */}

          <div className="relative">
            <button
              type="button"
              className="no-focus-outline flex items-center text-sm bg-white-900 rounded-full md:me-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
              id="user-menu-button"
              aria-expanded={isDropdownOpen}
              onClick={toggleDropdown}
            >
              <span className="sr-only">Open user menu</span>
              {user?.profilephoto ? (
                <img
                  className="w-8 h-8 rounded-full"
                  src={user.profilephoto}
                  alt="user photo"
                />
              ) : (
                <span className="material-symbols-outlined w-8 h-8 rounded-full flex items-center justify-center bg-gray-200">
                  person
                </span>
              )}
              <p className="hidden md:block text-dark ml-2">{user?.username}</p>{" "}
              <svg
                className="w-2.5 h-2.5 ms-2.5 text-dark hidden md:block"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 4 4 4-4"
                />
              </svg>
            </button>
            {isDropdownOpen && (
              <div
                className="absolute right-0 z-10 mt-2 w-48 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="user-menu-button"
                tabIndex="-1"
              >
                <div className="py-1" role="none">
                  <a
                    href="/profile"
                    className="block px-4 py-2 text-sm text-gray-700"
                    role="menuitem"
                    tabIndex="-1"
                    id="user-menu-item-0"
                  >
                    Profile
                  </a>
                  <a
                    href=""
                    onClick={handleLogout} // Call handleLogout on click
                    className="block px-4 py-2 text-sm text-gray-700"
                    role="menuitem"
                    tabIndex="-1"
                    id="user-menu-item-3"
                  >
                    Sign out
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

Header.propTypes = {
  isDropdownOpen: PropTypes.bool.isRequired,
  toggleDropdown: PropTypes.func.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
};

export default Header;
