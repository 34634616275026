import React from 'react';
import Select from 'react-select';

function MultiNewSelect({
    className,
    isMulti,
    placeHolder,
    options,
    id,
    value,
    onChange,
    name,
    disabled,
    onFocus,
    ...restProps
}) {
    const handleFocus = onFocus || (() => {});

    return (
        <Select
            isDisabled={disabled}
            value={value}
            onChange={onChange}
            options={options}
            isMulti={isMulti}
            name={name}
            placeholder={placeHolder}
            id={id}
            classNamePrefix="custom-select"
            onFocus={handleFocus}
            {...restProps}
        />
    );
}

export { MultiNewSelect };
