import React from "react";

const RadioButton = ({ name, value, checked, onChange, label, className }) => {
  return (
    <label className={`inline-flex items-center cursor-pointer ${className}`}>
      <input
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        className="hidden"
      />
      <span
        className={`w-4 h-4 inline-block mr-2 rounded-full border-2 border-gray-300 flex-shrink-0 ${
          checked ? "bg-[#0F6CBD] border-[#0F6CBD]" : "bg-white"
        }`}
      ></span>
      <span className="text-[14px] font-normal">{label}</span>
    </label>
  );
};

export default RadioButton;
