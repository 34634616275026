import React from "react";

const Loader = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen space-y-4">
      <div className="w-16 h-16 border-4 border-red-500 border-t-transparent border-solid rounded-full animate-spin"></div>
      <p className="text-lg text-gray-700">Loading...</p>
    </div>
  );
};

export default Loader;
