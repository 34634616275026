import React, { useState } from "react";
import PropTypes from "prop-types";

const Password = ({ placeholder, value, onChange, name, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="relative">
      <label className="block">
        <input
          type={showPassword ? "text" : "password"}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className={`border-b-2 bg-white border text-mute-text border-gray-300 rounded-md h-[32px] w-full mt-2 text-sm p-2 placeholder:text-sm ${
            name === "password" ? "border-b-[#797979]" : ""
          }`}
          id={props.id}
  disabled={props.disabled}
        />
        <span
          className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 cursor-pointer"
          onClick={togglePasswordVisibility}
        >
          <span className="material-symbols-outlined text-[20px]">
            {showPassword ? "visibility_off" : "visibility"}
          </span>
        </span>
      </label>
      {/* <span
        className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 cursor-pointer"
        onClick={togglePasswordVisibility}
      >
        <span className="material-symbols-outlined">
          {showPassword ? "visibility_off" : "visibility"}
        </span>
      </span> */}
    </div>
  );
};

Password.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
};

Password.defaultProps = {
  placeholder: "Password",
  name: "password",
};

export default Password;
