import React, { useState, useEffect } from "react";
import Button from "../customComponents/Button";
import Table from "../customComponents/Table";
import DatePicker from "../customComponents/DatePicker";
import FilterSelect from "../customComponents/FilterSelect";
import { createColumnHelper } from "@tanstack/react-table";
import SearchSelect from "../customComponents/SerachSelect";
import TransportationModal from "../customComponents/TransportationModal";
import { useNavigate } from "react-router-dom";
import { post } from "../utils/api";
import ConfirmationModal from "../customComponents/ConfirmationModal";
import { ToastContainer, toast } from "react-toastify";

const Transportations = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTransportation, setSelectedTransportation] = useState(null);
  const user = JSON.parse(localStorage.getItem("user"));
  const [tableData, setTableData] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [searchText, setSearchData] = useState("");
  const [vehicleOptions, setVehicleOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [vehicle, setVehicle] = useState("");
  const [city, setCity] = useState("");
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState("");
  const [formattedDate, setFormattedDate] =useState()


  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const filteredData = initialData.filter((item) => {
      // Convert values to lowercase for case-insensitive comparison
      const matchesSearch =
        !searchText || // Allow all if searchText is empty
        item.vehicletype?.toLowerCase().includes(searchText.toLowerCase()) ||
        item.vehiclename?.toLowerCase().includes(searchText.toLowerCase()) ||
        item.actype?.toLowerCase().includes(searchText.toLowerCase()) ||
        item.noofseats?.toLowerCase().includes(searchText.toLowerCase()) ||
        item.city?.toLowerCase().includes(searchText.toLowerCase()) ||
        `${item.priceperhead}`.toLowerCase().includes(searchText.toLowerCase());

      const matchesCity =
        !city || city.value === "All City" || item.city === city.value;
      const matchesVehicle =
        !vehicle ||
        vehicle.value === "All Hotel" ||
        item.vehicletype === vehicle.value;

        const matchesDay =!formattedDate ||  item.alterCreatedon == formattedDate;


      // Combine filters, allowing partial matches if others are not applied
      return matchesSearch && matchesCity && matchesVehicle && matchesDay;
    });

    setTableData(filteredData);
  }, [searchText, city, vehicle,selectedDate]);

  const fetchData = async () => {
    try {
      const data = {};
      const result = await post("/getAllTransport", data);

      // Transform result data for table
      const temp = result.data.map((list) => ({
        vehicletype: list.vehicaltype,
        vehiclename: list.vehicalname,
        actype: list.actype,
        noofseats: list.noofseats,
        city: list.city,
        alterCreatedon: new Date(list.createdon)
        .toLocaleDateString("en-GB")
        .replace(/\//g, "-"),
        priceperhead: `₹ ${list.priceperhead}`,
        ...list,
      }));

      // Extract unique city and hotel options
      const citySet = new Set(result.data.map((item) => item.city));
      const vehicalSet = new Set(result.data.map((item) => item.vehicaltype));

      const cityArray = [
        { label: "All City", value: "All City" },
        ...Array.from(citySet).map((city) => ({
          label: city,
          value: city,
        })),
      ];

      const vehicleArray = [
        { label: "All Vehicle", value: "All Vehicle" },
        ...Array.from(vehicalSet).map((vehicle) => ({
          label: vehicle,
          value: vehicle,
        })),
      ];

      // Update states
      setCityOptions(cityArray);
      setVehicleOptions(vehicleArray);
      setTableData(temp);
      setInitialData(temp);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleDateChange = (filterDate) => {
    // setSelectedDate(date);

    const date = new Date(filterDate);
    const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();
    
    const formattedDate = `${day}-${month}-${year}`;
    console.log(formattedDate);
    setFormattedDate(formattedDate)
        setSelectedDate(filterDate);

  };

  const columnHelper = createColumnHelper();
  const handleAddVehicle = () => {
    navigate("/add-transportation");
  };
  const columns = [
    columnHelper.accessor("slNo", {
      header: "Sl. No",
      cell: (info) => info.row.index + 1,
    }),
    columnHelper.accessor("vehicletype", {
      header: "VEHICLE TYPE",
    }),
    columnHelper.accessor("vehiclename", {
      header: "VEHICLE NAME",
    }),
    // columnHelper.accessor("actype", {
    //   header: "AC/ NON AC",
    // }),
    columnHelper.accessor("noofseats", {
      header: "NO. OF SEATS",
    }),
    columnHelper.accessor("city", {
      header: "CITY (SOURCE)",
    }),
    columnHelper.accessor("priceperhead", {
      header: "PRICE PER HEAD",
    }),

    columnHelper.accessor("actions", {
      header: "ACTIONS",
      cell: (info) => (
        <div className="flex gap-2">
          {(user?.role === "Admin" || user?.role === "Backend Team Manager") && (
            <button
              className="p-2 bg-gray-200 rounded-md"
              onClick={() => handleEditClick(info.row.original)}
            >
              <span className="material-symbols-outlined">edit</span>
            </button>
          )}
          <button
            className="p-2 bg-gray-200 rounded-md"
            onClick={() => handleViewClick(info.row.original)}
          >
            <span className="material-symbols-outlined">visibility</span>
          </button>
          {(user?.role === "Admin" || user?.role === "Backend Team Manager") && (
            <button
              onClick={() => handleDeleteClick(info.row.original)}
              className="p-2 bg-gray-200 text-red-500 rounded-md"
            >
              <span className="material-symbols-outlined">delete</span>
            </button>
          )}
        </div>
      ),
    }),
  ];
  const handleEditClick = (transportation) => {
    navigate("/add-transportation", {
      state: {
        transportData: transportation,
        isEdit: true,
      },
    });
  };

  const handleCloseModal = async () => {
    setDeleteModal(false);
  };

  const handleConfirmDelete = async () => {
    try {
      const result = await post("/deleteTransport", deleteItem);
      toast.success("Vehicle deleted successfully");
      setDeleteModal(false);
      fetchData();
    } catch (error) {
      toast.error("Vehicle delete failed");
      setDeleteModal(false);
    }
  };

  const handleViewClick = (transportation) => {
    setSelectedTransportation(transportation);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedTransportation(null);
  };

  const handleFormSelection = (e, type) => {
    if (type == "vehicletype") {
      if (e.label === "All Vehicle") {
        setVehicle("");
      } else {
        setVehicle(e);
      }
    } else {
      if (e.label === "All City") {
        setCity("");
      } else {
        setCity(e);
      }
    }
  };

  const handleSearch = (e) => {
    setSearchData(e.target.value);
  };

  const handleDeleteClick = async (data) => {
    setDeleteModal(true);
    setDeleteItem(data);
  };

  return (
    <div>
      <div className="flex justify-between">
        <div>
          <h2 className="text-primary-black text-[28px] font-semibold">
            Transportations
          </h2>
          <p className="text-[14px]">
            <span className="font-bold">Master Data</span> /{" "}
            <span className="font-regular">Transportations</span>
          </p>
        </div>
        <div className="flex mt-4 justify-end gap-4">
          <Button
            type="primary"
            label="Add Vehicle"
            onClick={handleAddVehicle}
          />
        </div>
      </div>

      {/* Filter Fields */}
      <div className="grid grid-cols-1 gap-4 mt-4 md:grid-cols-1 lg:flex lg:justify-between">
        <div className="w-full">
          <input
            type="text"
            className="outline-none p-2 rounded-l-lg w-full sm:w-5/12 h-[40px]"
            placeholder="Search..."
            value={searchText}
            onChange={(e) => handleSearch(e)}
          />
        </div>

        <div className="w-full md:w-auto md:flex md:gap-4">
          <div className="sm:w-full md:w-full lg:w-52">
            <SearchSelect
              placeholder="Vehicle Type"
              value={vehicle}
              onChange={(e) => handleFormSelection(e, "vehicletype")}
              options={vehicleOptions}
            />
          </div>
          <div className="sm:w-full md:w-full lg:w-52">
            <SearchSelect
              placeholder="City"
              value={city}
              options={cityOptions}
              onChange={(e) => handleFormSelection(e, "city")}
            />
          </div>

          <div className="sm:w-full md:w-full lg:w-52">
            <DatePicker
              selectedDate={selectedDate}
              onDateChange={handleDateChange}
            />
          </div>

        </div>
      </div>

      <div className="bg-white rounded-xl border-gray-300 mt-7 pb-6">
        <Table columns={columns} data={tableData} />
      </div>

      <ConfirmationModal
        isOpen={isDeleteModal}
        onClose={handleCloseModal}
        onConfirm={handleConfirmDelete}
        title="Delete Vehicle"
        message={`Are you sure you want to delete the vehicle "${deleteItem?.vehiclename}"?`}
      />

      {/* Transportation Details Modal */}
      {isModalOpen && selectedTransportation && (
        <TransportationModal
          isOpen={isModalOpen}
          onClose={closeModal}
          transportationData={selectedTransportation} // Ensure correct prop name
        />
      )}
      <ToastContainer />
    </div>
  );
};

export default Transportations;
