import { Outlet } from "react-router-dom";
import Header from "./widgets/Header";
import Navbar from "./widgets/Navbar";
import Footer from "./widgets/Footer";
import "./layout.css";
import { useState } from "react";

const Layout = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  return (
    <div className="layout">
      <Header
        className="header"
        isDropdownOpen={isDropdownOpen}
        toggleDropdown={toggleDropdown}
        toggleSidebar={toggleSidebar}
      />
      <div className="main">
        <Navbar
          className="sidebar"
          isSidebarOpen={isSidebarOpen}
          closeSidebar={closeSidebar}
        />
        <div className="content">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
