import React, { useState } from "react";
import InstitutionModal from "./InstitutionModal";
import { useNavigate } from "react-router-dom";
import noImg from "../assets/dummyEquipImg.png";
import Button from "./Button";
import { post } from "../utils/api";
import { toast, ToastContainer } from "react-toastify";
import log from "../assets/img/Itinerary/log.png";

const EnquiryCardComponent = ({ enquiry, institution }) => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handleViewClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleEditClick = () => {
    const institutionId = institution?.institutionid;
    const editenquiry = true;
    if ([1, 2].includes(enquiry.enquirystatusid)) {
      navigate("/add-enquiry", {
        state: { enquiry, institutionId, editenquiry },
      });
    }
  };

  const isDisabled = ![1, 2].includes(enquiry.enquirystatusid);

  const handleStatusChange = async (statusId) => {
    const payload = {
      enquiryid: enquiry.enquiryid,
      enquirystatusid: statusId,
    };

    try {
      const response = await post("/updateEnquiryStatus", payload);
      if (response.status) {
        toast.success("Enquiry status updated to Closed successfully");
        setTimeout(() => {
          window.location.reload();
        }, 1000); // Delay reload by 1 seconds
      } else {
        toast.error("Error: Non-successful response");
      }
    } catch (error) {
      toast.error("Error updating enquiry status");
    }
  };
  const handleLogClick = (enquiryId) => {
    navigate(`/enquiry-view-logs/${enquiryId}`);
  };
  return (
    <div>
      <div className="block md:block lg:block xl:flex items-center p-4 bg-white rounded-lg shadow-md w-full">
        <div className="flex items-start w-full lg:w-full xl:w-1/2 mb-6 xl:mb-0 ">
          <div className="flex-shrink-0 bg-[#EFEFF0] p-2 rounded">
            <img src={noImg} alt="icon" className="w-7 h-7" />
          </div>
          <div className="flex-grow ml-8">
            <h2 className="text-[16px] font-bold text-[#121212]">
              {enquiry.enquiryname}
            </h2>
            <p className="text-[14px] font-normal text-[#797979]">
              Date of Travel{" "}
              {new Date(enquiry.dateoftravel).toLocaleDateString("en-CA")}
            </p>
          </div>
          <div className="flex-col items-center mx-2 md:mx-3 lg:mx-4 hidden md:inline-flex lg:inline-flex">
            <span className="text-[14px] font-bold text-[#121212] text-center">
              No. of Days
            </span>
            <span className="text-[14px] font-normal text-[#797979]">
              {enquiry.noofdays}
            </span>
          </div>
          <div className="flex-col items-center mx-2 md:mx-3 lg:mx-4 hidden md:inline-flex lg:inline-flex">
            <span className="text-[14px] font-bold text-[#121212] text-center">
              No. of Nights
            </span>
            <span className="text-[14px] font-normal text-[#797979]">
              {enquiry.noofnight}
            </span>
          </div>
          {enquiry?.prices && enquiry.enquirystatusid === 2 && (
            <div className="flex-col items-center md:mx-2 lg:mx-6 hidden md:inline-flex lg:inline-flex">
              <span className="text-[14px] font-bold text-[#121212] text-center">
                {"₹"} {enquiry?.prices}
              </span>
              <span className="text-[14px] font-normal text-[#797979]">
                {" / Person"}
              </span>
            </div>
          )}
          {enquiry?.nextfollowupdate && enquiry.enquirystatusid === 1 && (
            <div className="flex-col items-center md:mx-2 lg:mx-6 hidden md:hidden lg:inline-flex">
              <span className="text-[14px] font-bold text-[#121212] text-center">
                {" Follow Up Date"}
              </span>
              <span className="text-[14px] font-normal text-[#121212] text-center">
                {enquiry?.nextfollowupdate}
              </span>
            </div>
          )}
        </div>
        <div className="block md:flex items-center w-full lg:w-full xl:w-1/2 justify-between">
          <div className="items-center w-1/2 hidden md:inline-flex lg:inline-flex mx-8">
            <div className="flex-col items-start mb-4">
              <span className="text-gray-500">{enquiry.enquirysource}</span>
              <span className="material-symbols-outlined text-[18px]">
                location_on
              </span>
            </div>
            <div className="flex-grow mx-2 border-t border-dashed border-gray-400"></div>
            <div className="flex-col items-end mt-4">
              <span className="material-symbols-outlined text-[18px]">
                location_on
              </span>
              <span className="text-gray-500">{enquiry.destination}</span>
            </div>
          </div>
          {/* Commenting out the close enquiry button for now */}
          {/* {enquiry.enquirystatusid === 2 && (
            <Button
              label="Close Enquiry"
              onClick={() => handleStatusChange(3)}
              disabled={enquiry.enquirystatusid !== 2}
              className="mt-2"
            />
          )} */}
          <div className="block md:flex text-end md:items-center space-x-2">
            <a
              className="inline-block md:block p-2 bg-gray-200 rounded-md h-[40px] hover:cursor-pointer"
              onClick={handleViewClick}
            >
              <span className="material-symbols-outlined text-[#121212]">
                visibility
              </span>
            </a>
            <a
              className={`inline-block md:block p-2 bg-gray-200 rounded-md h-[40px] ${
                isDisabled
                  ? "cursor-not-allowed opacity-50"
                  : "hover:cursor-pointer"
              }`}
              onClick={!isDisabled ? handleEditClick : null}
              aria-disabled={isDisabled}
            >
              <span className="material-symbols-outlined text-[#121212]">
                edit
              </span>
            </a>
            <button
              className="p-2 bg-gray-200 rounded-md"
              onClick={() => handleLogClick(enquiry.enquiryid)}
            >
              <img
                src={log}
                alt="Enquiry Logo"
                className="lg:max-w-full lg:h-auto lg:w-4 w-6 max-w-28"
              />
            </button>
          </div>
        </div>
      </div>
      {showModal && (
        <InstitutionModal
          show={showModal}
          onClose={handleCloseModal}
          enquiry={enquiry}
          institution={institution}
        />
      )}

      <ToastContainer />
    </div>
  );
};

export default EnquiryCardComponent;
