import React, { useEffect } from "react";
import Paper from "../widgets/Paper";
import InputText from "../customComponents/InputText";
import Button from "../customComponents/Button";
import { post } from "../utils/api";

import { toast, ToastContainer } from "react-toastify";

const ProfileCard = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  if (!user) {
    return <div>Please Logout and login if you can't see any datas</div>;
  }

  const [settingsName, setSettingsName] = React.useState("");
  const [settingsGSTValue, setSettingsGSTValue] = React.useState("");
  const [settingsMarkupValue, setSettingsMarkupValue] = React.useState("");
  const [settings, setSettings] = React.useState([]);

  useEffect(() => {
   

    getSettings();
  }, []);

  let settingsid;
  const [formValues, setFormValues] = React.useState({
    settinsname: "",
    settingsvalue: "",
  });


  const getSettings = async () => {
    try {
      const req = {};
      const response = await post("/getSettings",req);
      setSettings(response.data);
      // Map the settings to the respective fields
      response.data.forEach(setting => {
        if (setting.settingsid === 1) {
          setSettingsMarkupValue(setting.settingsvalue);
        } else if (setting.settingsid === 2) {
          setSettingsGSTValue(setting.settingsvalue);
        }
      });
 
    } catch (error) {
      console.error("Error fetching settings:", error);
    }
  };

  

  const handleSaveSettings = (name) => async () => {
    if (name === "markup") {
      settingsid = 1;
    } else {
      settingsid = 2;
    }

    let settingsVal = name === "markup" ? settingsMarkupValue : settingsGSTValue;
    const basePayload = {
      settingsvalue: settingsVal,
      settingsid: settingsid,
    };

    try {
      const response = await post("/addSettings", basePayload);
      if (response) {
     
        toast.success("Settings saved successfully");
      }
    } catch (error) {
      console.error("Error while saving settings", error);
    }

    
  };

  return (
    <div className="min-h-screen p-6">
      <div className="w-full mx-auto">
        <div className="flex justify-between mb-6">
          <div>
            <h2 className="text-primary-black text-2xl font-semibold">
              Profile
            </h2>
            <p className="text-[14px]">
              <span>My Profile</span>
            </p>
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-md overflow-hidden">
          <div className="p-6">
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5  gap-6 items-center">
              <div className="md:col-span-1 flex justify-center md:justify-start">
                {user?.profilephoto ? (
                  <img
                    className="rounded-full border-4 border-white w-32 h-32"
                    src={user.profilephoto}
                    alt="Profile"
                  />
                ) : (
                  <span className="material-symbols-outlined w-32 h-32 rounded-full flex items-center justify-center bg-gray-200 text-7xl">
                    person
                  </span>
                )}
              </div>
              <div className="md:col-span-4">
                <h2 className="text-3xl font-semibold text-gray-800">
                  {user?.username || "N/A"}
                </h2>
                <p className="text-gray-500">{user?.role}</p>
                <div className="mt-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4  gap-4">
                  <div className="mt-7 items-center justify-center">
                    <span className="material-symbols-outlined text-[22px] leading-5 text-gray-500">
                      mail
                    </span>
                    <div className="mt-2">
                      <p className="text-[14px] leading-5 text-gray-500">
                        {user?.email || "N/A"}
                      </p>
                    </div>
                  </div>
                  <div className="mt-7">
                    <span className="material-symbols-outlined text-[22px] leading-5 text-gray-500">
                      call
                    </span>
                    <div className="mt-2">
                      <p className="text-[14px] leading-5 text-gray-500">
                        {user?.mobilenumber || "N/A"}
                      </p>
                    </div>
                  </div>
                  <div className="mt-7">
                    <span className="material-symbols-outlined text-[22px] leading-5 text-gray-500">
                      location_on
                    </span>
                    <div className="mt-2">
                      <p className="text-[14px] leading-5 text-gray-500">
                        {user?.assigncity || "N/A"}
                      </p>
                    </div>
                  </div>
                  <div className="mt-7">
                    <span className="material-symbols-outlined text-[22px] leading-5 text-gray-500">
                      person_add
                    </span>
                    <div className="mt-2">
                      <p className="text-[14px] leading-5 text-gray-500">
                        {user?.role || "N/A"}{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {user?.role !== "Admin" && (
              <div className="mt-7">
                <h3 className="text-lg font-semibold text-gray-800 mb-7">
                  Activities
                </h3>
                <div className="grid w-full lg:w-3/4 h-full grid-cols-4 gap-4">
                  <div className="col-span-1">
                    <div className="p-2 bg-[#EFEFF0] rounded-lg text-center text-[#797979]">
                      <p className="text-[20px] font-semibold">
                        {user?.openedcount}
                      </p>
                      <p className="text-[16px] font-normal">Opened Enquiry</p>
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="p-2 bg-[#EFEFF0] rounded-lg text-center text-[#797979]">
                      <p className="text-[20px] font-semibold">
                        {user?.confirmedcount}
                      </p>
                      <p className="text-[16px] font-normal">
                        Confirmed Enquiry
                      </p>
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="p-2 bg-[#EFEFF0] rounded-lg text-center text-[#797979]">
                      <p className="text-[20px] font-semibold">
                        {user?.closedcount}
                      </p>
                      <p className="text-[16px] font-normal">Closed Enquiry</p>
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="p-2 bg-[#EFEFF0] rounded-lg text-center text-[#797979]">
                      <p className="text-[20px] font-semibold">
                        {user?.cancelledcount}
                      </p>
                      <p className="text-[16px] font-normal">
                        Canceled Enquiry
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {user?.role == "Admin" && (
        <Paper className="my-8 pt-6 pb-6">
          <Paper className="my-8 mt-4 border">
            <h1 className="text-[20px] font-bold mb-4">For Admin Only</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-4">
              <div className="col-span-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                <div>
                  <label className="text-[16px] font-semibold">
                    Markup %<span className="text-red-500">*</span>
                  </label>
                  <InputText
                    placeholder="Enter the value in %"
                    name="settingsvalue"
                    type={"number"}
                    value={settingsMarkupValue}
                    onChange={(e) => {
                      setSettingsMarkupValue(e.target.value);
                    }}
                  />
                </div>

                <div className="pt-6">
                  <Button
                    className="w-full md:w-auto"
                    type="primary"
                    label={"Save"}
                    onClick={handleSaveSettings("markup")}
                  />
                </div>
                <div>
                  <label className="text-[16px] font-semibold mb-2">
                    GST %<span className="text-red-500">*</span>
                  </label>
                  <InputText
                    placeholder="Enter the value in %"
                    name="city"
                    value={settingsGSTValue}
                    onChange={(e) => {
                      setSettingsGSTValue(e.target.value);
                    }}
                  />{" "}
                </div>
                <div className="pt-6">
                  <Button
                    className="w-full md:w-auto"
                    type="primary"
                    label={"Save"}
                    onClick={handleSaveSettings("GST")}
                  />
                </div>
              </div>
            </div>
          </Paper>
          <ToastContainer />

        </Paper>
        
      )}
    </div>
  );
};

export default ProfileCard;
