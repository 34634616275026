import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginBg from "../assets/img/login-bg.svg";
import InputText from "../customComponents/InputText";
import { post } from "../utils/api";
import { toast, ToastContainer } from "react-toastify";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (!email) {
      toast.error("Please enter your email.");
      return;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    setLoading(true);
    try {
      const payload = { email };
      await post("/requestPasswordReset", payload);
      toast.success("Password reset link sent to your email.");
      // Delay navigation to allow toast to appear
      setTimeout(() => {
        navigate("/login");
      }, 1000); // 1 second delay
    } catch (error) {
      console.error("Error requesting password reset:", error);
      toast.error("An error occurred while requesting the password reset.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4 bg-white login-div overflow-auto md:overflow-auto lg:overflow-hidden">
      <div className="hidden lg:block">
        <img
          src={LoginBg}
          className="rounded rounded-r-2xl"
          alt="Login background"
        />
      </div>
      <div>
        <div className="mt-16 flex flex-col items-center justify-center">
          <img
            src="https://crm.fuelifeexplorers.com/crmImages/logo/1680004129Fuelife-Explorers.png"
            className="react h-[80px]"
            alt="React logo"
          />
        </div>
        <div className="px-20">
          <h1 className="text-[28px] text-[#121212] font-semibold my-4">
            Forgot Password
          </h1>
          <p className="mb-6 text-[14px] md:text-[14px] lg:text-[16px]">
            Provide your account’s email to reset your password.
          </p>
          <div className="text-left mb-4">
            <label className="text-[14px] md:text-[16px] font-semibold mb-4">
              Email
            </label>
            <InputText
              value={email}
              placeholder="Enter your email"
              onChange={(e) => setEmail(e.target.value)}
              className="border-b-2 bg-white border text-mute-text border-gray-300 rounded-md h-[32px] w-full mt-2 text-sm p-2 placeholder:text-sm border-b-[#797979]"
            />
          </div>
          <button
            type="button"
            className={`w-56 ${
              loading
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-red-500 hover:bg-red-600"
            } text-white py-2 rounded-3xl shadow-lg mb-6`}
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? "Submitting..." : "Submit"}
          </button>
          <div className="items-center mb-6">
            <a href="/login" className="text-[#121212] hover:underline">
              Go back to Login
            </a>
          </div>
          <div className="text-center mt-20 md:mt-20 lg:mt-36 mb-4">
            <p className="text-[#797979] text-[14px]">
              Copyright @ Fuelife 2024
            </p>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ForgotPassword;
