import PropTypes from "prop-types";

const Button = ({ type, label, onClick, className, style }) => {
  const baseClasses = "px-4 md:px-6 py-0.5  md:py-2 rounded transition duration-300 ease-in-out";
  const primaryClasses =
    "bg-black text-white hover:bg-gray-800  h-[40px] md:h-[42px] leading-[10px] ml-2 text-[16px] md:text-[16px] mb-2";
  const secondaryClasses =
    "bg-transparent text-black border border-black hover:bg-gray-100 h-[40px] md:h-[42px] leading-[10px] ml-2 text-[16px] md:text-[16px] mb-2";
  const tertiaryClasses =
    "bg-[#D34B44] text-white hover:bg-[#B73B2C] h-[40px] md:h-[42px] leading-[10px] ml-2 text-[16px] md:text-[16px] mb-2"; // Red variant classes

  const buttonClasses = `${baseClasses} ${
    type === "primary"
      ? primaryClasses
      : type === "secondary"
      ? secondaryClasses
      : tertiaryClasses
  } ${className}`;

  return (
    <button
      className={buttonClasses}
      onClick={onClick}
      style={{ borderRadius: "10px", ...style }}
    >
      {label}
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf(["primary", "secondary", "red"]).isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
};

Button.defaultProps = {
  onClick: () => {},
  className: "",
  style: {},
};

export default Button;
