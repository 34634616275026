import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const TextEditor = ({ initialValue, onChange }) => {
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ align: [] }],
      ["link"],
      ["clean"],
      [{ color: [] }, { background: [] }],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "align",
    "link",
    "color",
    "background",
  ];

  // const modules = {
  //   toolbar: [
  //     [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  //     [{ 'font': [] }],
  //     [{ 'size': ['small', false, 'large', 'huge'] }],
  //     ['bold', 'italic', 'underline', 'strike'],
  //     [{ 'color': [] }, { 'background': [] }],
  //     [{ 'script': 'sub'}, { 'script': 'super' }],
  //     [{ 'list': 'ordered'}, { 'list': 'bullet' }],
  //     [{ 'indent': '-1'}, { 'indent': '+1' }],
  //     [{ 'direction': 'rtl' }],
  //     [{ 'align': [] }],
  //     ['link', 'image', 'video'],
  //     ['blockquote', 'code-block'],
  //     ['clean']
  //   ]
  // };

  // const formats = [
  //   'header', 'font', 'size',
  //   'bold', 'italic', 'underline', 'strike',
  //   'color', 'background',
  //   'script',
  //   'list', 'bullet', 'indent',
  //   'direction',
  //   'align',
  //   'link', 'image', 'video',
  //   'blockquote', 'code-block'
  // ];

  return (
    <ReactQuill
      theme="snow"
      value={initialValue || ""}
      onChange={onChange}
      modules={modules}
      formats={formats}
      style={{ height: "200px", marginBottom: "50px" }}
    />
  );
};

export default TextEditor;
