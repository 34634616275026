import "./layout.css";

const Footer = () => {
  return (
    <div className="bg-white border-gray-200 ">
      <div className="items-center justify-between mx-auto p-4">
        <div className="items-center text-center">
          <p className="text-[14px] font-normal">Copyright @ Fuelife 2024</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
