import React from "react";

const LoaderComponent = () => {
  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="relative w-16 h-16">
        {[0, 120, 240].map((rotation, index) => (
          <div
            key={index}
            className="absolute inset-0 animate-[spin_2s_cubic-bezier(0.4,0,0.2,1)_infinite]"
            style={{
              animationDelay: `-${index * 0.4}s`,
            }}
          >
            <svg viewBox="0 0 50 50" className="w-full h-full">
              <circle
                cx="25"
                cy="25"
                r="20"
                fill="none"
                strokeWidth="5"
                strokeLinecap="round"
                strokeDasharray="20 60"
                transform={`rotate(${rotation} 25 25)`}
                className="animate-[loader-color_2s_cubic-bezier(0.4,0,0.2,1)_infinite] origin-center"
                style={{
                  animationDelay: `-${index * 0.4}s`,
                  filter: "drop-shadow(0 0 2px rgba(220, 38, 38, 0.5))",
                }}
              />
            </svg>
          </div>
        ))}
      </div>

      <style jsx>{`
        @keyframes loader-color {
          0%,
          45% {
            stroke: rgb(220, 38, 38);
            stroke-width: 5;
          }
          50%,
          95% {
            stroke: rgb(75, 85, 99);
            stroke-width: 5;
          }
          100% {
            stroke: rgb(220, 38, 38);
            stroke-width: 5;
          }
        }
      `}</style>
    </div>
  );
};

export default LoaderComponent;
