import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import "./layout.css";

const Navbar = ({ isSidebarOpen, closeSidebar }) => {
  const sidebarRef = useRef(null);
  const [isInstitutionsOpen, setIsInstitutionsOpen] = useState(false);
  const [isUsersOpen, setIsUsersOpen] = useState(false);
  const [isPackagesOpen, setIsPackagesOpen] = useState(false); // Separate state for Packages
  const [isMasterDataOpen, setIsMasterDataOpen] = useState(false);
  const [isItineraryOpen, setIsItineraryOpen] = useState(false);
  const [role, setRole] = useState("");

  useEffect(() => {
    // Get user role from localStorage
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setRole(user.role);
    }

    function handleClickOutside(event) {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        closeSidebar();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [closeSidebar]);

  const renderUsersTab = () => (
    <li>
      <button
        className="navbar-item text-base font-normal text-gray-900 w-full text-left"
        onClick={() => setIsUsersOpen(!isUsersOpen)}
      >
        <span className="material-symbols-outlined">person</span>
        <span className="ml-3">Users</span>
        <span className="material-symbols-outlined ml-auto">
          {isUsersOpen ? "expand_less" : "expand_more"}
        </span>
      </button>
      {isUsersOpen && (
        <ul className="pl-8 space-y-2">
          <li>
            <a
              href="/users"
              className="navbar-item text-base font-normal text-gray-900"
            >
              All Users
            </a>
          </li>
          <li>
            <a
              href="/add-user"
              className="navbar-item text-base font-normal text-gray-900"
            >
              Add User
            </a>
          </li>
        </ul>
      )}
    </li>
  );

  const renderInstitutionsTab = () => (
    <li>
      <button
        className="navbar-item text-base font-normal text-gray-900 w-full text-left"
        onClick={() => setIsInstitutionsOpen(!isInstitutionsOpen)}
      >
        <span className="material-symbols-outlined">apartment</span>
        <span className="ml-3">Institutions</span>
        <span className="material-symbols-outlined ml-auto">
          {isInstitutionsOpen ? "expand_less" : "expand_more"}
        </span>
      </button>
      {isInstitutionsOpen && (
        <ul className="pl-8 space-y-2">
          <li>
            <a
              href="/institutions"
              className="navbar-item text-base font-normal text-gray-900"
            >
              All Institutions
            </a>
          </li>
          {(role === "Admin" || role === "Sales Manager") && (
            <li>
              <a
                href="/add-institutions"
                className="navbar-item text-base font-normal text-gray-900"
              >
                Add Institution
              </a>
            </li>
          )}
        </ul>
      )}
    </li>
  );

  const renderItineraryTab = () => (
    <li>
      <button
        className="navbar-item text-base font-normal text-gray-900 w-full text-left"
        onClick={() => setIsItineraryOpen(!isItineraryOpen)}
      >
        <span class="material-symbols-outlined">
          distance
        </span>
        <span className="ml-3">Itinerary</span>
        <span className="material-symbols-outlined ml-auto">
          {isItineraryOpen ? "expand_less" : "expand_more"}
        </span>
      </button>
      {isItineraryOpen && (
        <ul className="pl-8 space-y-2">
          <li>
            <a
              href="/all-itinerary"
              className="navbar-item text-base font-normal text-gray-900"
            >
              All Itinerary
            </a>
          </li>
          <li>
            <a
              href="/add-itinerary"
              className="navbar-item text-base font-normal text-gray-900"
            >
              Add Itinerary
            </a>
          </li>
        </ul>
      )}
    </li>
  );


  return (
    <>
      {isSidebarOpen && (
        <>
          <div
            className="fixed inset-0 bg-black opacity-50 z-30"
            onClick={closeSidebar}
          ></div>
          <div
            ref={sidebarRef}
            id="drawer-navigation"
            className="fixed top-0 left-0 z-40 h-screen p-4 overflow-y-auto transition-transform translate-x-0 bg-white w-64 light:bg-white-800"
            tabIndex="-1"
            aria-labelledby="drawer-navigation-label"
          >
            <a href="/">
              <img
                src="https://crm.fuelifeexplorers.com/crmImages/logo/1680004129Fuelife-Explorers.png"
                className="react h-[50px]"
                alt="React logo"
              />
            </a>
            <div className="py-4 overflow-y-auto">
              <ul className="space-y-2">
                {/* Dashboard Item */}
                <li>
                  <a
                    href="/dashboard"
                    className="navbar-item text-base font-normal text-gray-900"
                  >
                    <span className="material-symbols-outlined">
                      stacked_bar_chart
                    </span>{" "}
                    <span className="ml-3">Dashboard</span>
                  </a>
                </li>


                {renderInstitutionsTab()}
                {/* Packages Item */}
                <li>
                  <button
                    className="navbar-item text-base font-normal text-gray-900 w-full text-left flex items-center"
                    onClick={() => setIsPackagesOpen(!isPackagesOpen)}
                  >
                    <span class="material-symbols-outlined">
                      move
                    </span>
                    <span className="ml-3">Packages</span>
                    <span className="material-symbols-outlined ml-auto">
                      {isPackagesOpen ? "expand_less" : "expand_more"}
                    </span>
                  </button>
                  {isPackagesOpen && (
                    <ul className="pl-8 space-y-2">
                      <li>
                        <a
                          href="/all-packages"
                          className="navbar-item text-base font-normal text-gray-900"
                        >
                          All Packages
                        </a>
                      </li>
                      <li>
                        <a
                          href="/add-packages"
                          className="navbar-item text-base font-normal text-gray-900"
                        >
                          Add Packages
                        </a>
                      </li>
                      <li>
                        <button
                          className="navbar-item text-base font-normal text-gray-900 w-full text-left flex items-center"
                          onClick={() => setIsMasterDataOpen(!isMasterDataOpen)}
                        >
                          Master Data
                          <span className="material-symbols-outlined ml-auto">
                            {isMasterDataOpen ? "expand_less" : "expand_more"}
                          </span>
                        </button>
                        {isMasterDataOpen && (
                          <ul className="pl-6 space-y-2">
                            <li>
                              <a
                                href="/hotels"
                                className="navbar-item text-base font-normal text-gray-900"
                              >
                                Hotels
                              </a>
                            </li>
                            <li>
                              <a
                                href="/transportation"
                                className="navbar-item text-base font-normal text-gray-900"
                              >
                                Transportation
                              </a>
                            </li>
                            <li>
                              <a
                                href="/addons"
                                className="navbar-item text-base font-normal text-gray-900"
                              >
                                Addons
                              </a>
                            </li>
                          </ul>
                        )}
                      </li>
                    </ul>
                  )}
                </li>

                {/* Institutions Item */}

                {renderItineraryTab()}
                <li>
                  <a
                    href="/assigned-institution"
                    className="navbar-item text-base font-normal text-gray-900"
                  >
                    <span class="material-symbols-outlined">
                      person_check
                    </span>{" "}
                    <span className="ml-3">Assigned Institution</span>
                  </a>
                </li>
                {/* Users Item */}
                {role === "Admin" && renderUsersTab()}
                {role === "Sales Manager" && (
                  <li>
                    <a
                      href="/users"
                      className="navbar-item text-base font-normal text-gray-900"
                    >
                      <span className="material-symbols-outlined">person</span>
                      <span className="ml-3">Users</span>
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </>
      )}
    </>
  );
};

Navbar.propTypes = {
  isSidebarOpen: PropTypes.bool.isRequired,
  closeSidebar: PropTypes.func.isRequired,
};

export default Navbar;
