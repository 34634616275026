import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactPaginate from "react-paginate";
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  useReactTable,
  getFilteredRowModel,
} from "@tanstack/react-table";
import noImg from "../assets/nodata.png";

const Table = ({ columns, data, showFilters = false }) => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: { pagination },
  });

  const pageCount = table.getPageCount();
  const pageIndex = table.getState().pagination.pageIndex;
  const canPreviousPage = table.getCanPreviousPage();
  const canNextPage = table.getCanNextPage();
  const headerGroups = table.getHeaderGroups();

  const handlePageClick = (event) => {
    const newPageIndex = event.selected;
    table.setPageIndex(newPageIndex);
  };

  return (
    <>
      <div className="overflow-auto">
        <table className="min-w-full border-collapse rounded-lg">
          <thead className="bg-[#EFEFF0] h-14 rounded-lg">
            {headerGroups.map((headerGroup) => (
              <tr
                key={headerGroup.id}
                className="rounded-lg border-b-2  border-gray-500"
              >
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className="px-4 py-2 text-left text-[14px] font-semibold text-gray-900 min-w-40"
                  >
                    <div
                      className={
                        header.column.getCanSort()
                          ? "cursor-pointer select-none"
                          : ""
                      }
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {{
                        asc: (
                          <span className="ml-1 material-symbols-outlined text-xs">
                            expand_less
                          </span>
                        ),
                        desc: (
                          <span className="ml-1 material-symbols-outlined text-xs">
                            expand_more
                          </span>
                        ),
                      }[header.column.getIsSorted()] ?? null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
            {showFilters &&
              headerGroups.map((headerGroup) => (
                <tr
                  key={`${headerGroup.id}-filter`}
                  className="border-b border-gray-300"
                >
                  {headerGroup.headers.map((header) => (
                    <th
                      key={`${header.id}-filter`}
                      colSpan={header.colSpan}
                      className="px-4 py-2"
                    >
                      {header.column.getCanFilter() ? (
                        <Filter column={header.column} table={table} />
                      ) : null}
                    </th>
                  ))}
                </tr>
              ))}
          </thead>
          <tbody>
            {data.length === 0 ? (
              <tr>
                <td
                  colSpan={columns.length}
                  className="text-center py-4 text-gray-500"
                >
                  <div className="flex flex-col items-center">
                    <img
                      src={noImg}
                      alt="No Table data found"
                      className="h-48 mb-4"
                    />
                    <p className="text-lg">No data found.</p>
                  </div>
                </td>
              </tr>
            ) : (
              table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  className="border-b border-gray-300 hover:bg-gray-100"
                >
                  {row.getVisibleCells().map((cell, index) => (
                    <td
                      key={cell.id}
                      className="px-4 py-2 text-sm text-gray-900"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div className="flex justify-center items-center mt-4">
        <ReactPaginate
          previousLabel={
            <span className="material-symbols-outlined">chevron_left</span>
          }
          nextLabel={
            <span className="material-symbols-outlined">chevron_right</span>
          }
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      </div>
    </>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  showFilters: PropTypes.bool,
};

export default Table;

function Filter({ column, table }) {
  const isExcluded = column.columnDef.excludeFromFilter || false;
  if (isExcluded) return null;

  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);
  const columnFilterValue = column.getFilterValue();

  return typeof firstValue === "number" ? (
    <div className="flex space-x-2">
      <input
        type="number"
        value={columnFilterValue?.[0] ?? ""}
        onChange={(e) =>
          column.setFilterValue((old) => [e.target.value, old?.[1]])
        }
        placeholder={`Min`}
        className="w-20 p-2 border border-gray-300 rounded"
      />
      <input
        type="number"
        value={columnFilterValue?.[1] ?? ""}
        onChange={(e) =>
          column.setFilterValue((old) => [old?.[0], e.target.value])
        }
        placeholder={`Max`}
        className="w-20 p-2 border border-gray-300 rounded"
      />
    </div>
  ) : (
    <input
      type="text"
      value={columnFilterValue ?? ""}
      onChange={(e) => column.setFilterValue(e.target.value)}
      placeholder={`Search...`}
      className="w-full p-2 border border-gray-300 rounded"
    />
  );
}

Filter.propTypes = {
  column: PropTypes.object.isRequired,
  table: PropTypes.object.isRequired,
};
