import React from "react";
import PropTypes from "prop-types";

const TextArea = ({ value, onChange, placeholder, rows, label, ...props }) => {
  return (
    <div>
      {label && (
        <label className="block text-sm font-medium text-gray-700 mb-1">
          {label}
        </label>
      )}
      <textarea
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        rows={rows}
        className="border-b-2 bg-white border text-mute-text border-gray-300 rounded-md h-32 w-full mt-2 text-sm p-2 placeholder-text-sm focus:outline-none focus:border-blue-500"
        {...props}
      />
    </div>
  );
};

TextArea.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  label: PropTypes.string,
};

TextArea.defaultProps = {
  placeholder: "Enter text...",
  rows: 4,
};

export default TextArea;
