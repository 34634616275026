import React, { useEffect, useState } from "react";
import InputText from "../customComponents/InputText";
import Time from "../customComponents/Time";
import PackageUpload from "./PackageUpload";


const DayPlan = ({ dayNumber, initialTimeSlots = [], planName01 = '',daywiseImage=null, onChange, onDelete }) => {
  const [plans, setPlans] = useState(
    initialTimeSlots.length > 0
      ? initialTimeSlots.map((slot, index) => ({
        id: index + 1,
        time: slot.time,
        planDetail: slot.planDetails,
      }))
      : [{ id: 1, time: "00:00:00", planDetail: "" }]
  );
  const [planName, setPlanName] = useState(planName01);

  const [formData, setFormData1] = useState({ logo: daywiseImage });

  const addPlanDetail = () => {
    setPlans([
      ...plans,
      { id: plans.length + 1, time: "00:00:00", planDetail: "" },
    ]);
  };

  const removePlanDetail = (id) => {
    setPlans(plans.filter((plan) => plan.id !== id));
  };

  const updatePlanDetail = (id, key, value) => {
    setPlans(
      plans.map((plan) => (plan.id === id ? { ...plan, [key]: value } : plan))
    );
  };
  // Add useEffect to format and send data when plans or planName changes
  useEffect(() => {
    const timeSlots = plans.map((plan) => ({
      time: plan.time,
      planDetails: plan.planDetail,
    }));

    onChange({
      dayNumber,
      planName,
      timeSlots,
      formData,
    });
  }, [plans, planName, dayNumber, onChange]);

  const handleUploadSelectNew = (file) => {
    if (!file) return;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setFormData1({ ...formData, logo: reader.result });
    };
    reader.onerror = (error) => {
      console.error("Error converting file to Base64:", error);
    };
  };

  return (
    <div className="bg-gray-100 p-6 rounded-lg shadow-md">
      <div className="mb-4">
        <div className="relative grid grid-cols-3 items-center w-full">
          <div className="mt-3">
            <PackageUpload
              onFileSelectNew={handleUploadSelectNew}
              initialPreviewUrl={formData?.logo ? formData?.logo : null}
              // fileValidation={validateFile}
              handleRemove={() => {
                setFormData1(null);
              }}
              labelText="Upload Image"
            />
          </div>
          <h2 className="text-lg font-bold text-center col-span-1">
            Day {dayNumber}
          </h2>
          <button
            onClick={onDelete}
            className="absolute top-0 right-0 p-2 rounded bg-transparent text-red-500 hover:bg-transparent focus:outline-none focus:ring-0 border-none flex justify-center items-center"
          >
            <span className="material-symbols-outlined text-4xl">delete</span>
          </button>
        </div>
        {/* Input Field */}
        <div className="mt-4">
          <InputText
            placeholder="Enter plan name here..."
            value={planName}
            onChange={(e) => setPlanName(e.target.value)}
          />
        </div>
      </div>
      <div className="grid grid-cols-12 gap-4 mb-2">
        <h4 className="col-span-3 font-semibold text-gray-700">Time</h4>
        <h4 className="col-span-8 font-semibold text-gray-700">Plan</h4>
      </div>

      <div className="space-y-4">
        {plans.map((plan, index) => (
          <div key={plan.id} className="grid grid-cols-12 items-center gap-4">
            <div className="col-span-3">
              <Time
                value={plan.time}
                onChange={(e) =>
                  updatePlanDetail(plan.id, "time", e.target.value)
                }
              />
            </div>
            <div className="col-span-8">
              <InputText
                placeholder="Enter plan details here..."
                value={plan.planDetail}
                onChange={(e) =>
                  updatePlanDetail(plan.id, "planDetail", e.target.value)
                }
              />
            </div>
            <button
              onClick={() =>
                index === plans.length - 1
                  ? addPlanDetail()
                  : removePlanDetail(plan.id)
              }
              className={`col-span-1 p-2 rounded ${index === plans.length - 1
                ? "bg-black text-white"
                : "bg-red-500 text-white hover:bg-red-700"
                } flex justify-center items-center`}
            >
              <span className="material-symbols-outlined">
                {index === plans.length - 1 ? "add" : "delete"}
              </span>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DayPlan;
