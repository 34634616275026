import React, { useEffect, useState } from "react";
import Table from "../customComponents/Table";
import FilterSelect from "../customComponents/FilterSelect";
import { createColumnHelper } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import DatePicker from "../customComponents/DatePicker";
import { post } from "../utils/api";
import ConfirmationModal from "../customComponents/ConfirmationModal";
import SearchSelect from "../customComponents/SerachSelect";



const AssignedInstitution = () => {
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [schoolOptions, setSchoolOptions] = useState([]);

  const [searchText, setSearchData] = useState("");
  const [school, setSchool] = useState("");
  const [status, setStatus] = useState("");
  const [salesPerson, setSalesPerson] = useState("");
  const [salesOptions, setSalesOptions] = useState([]);
  const [deleteItem ,setDeleteItem] = useState("");

  console.log(deleteItem, 'deleteItem')


  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);


  // salesperson: list.username,
  // institution: list.institutionname,
  // status: list.status,
  // remarks: '-',


  useEffect(() => {
    const filteredData = initialData.filter((item) => {
      // Convert values to lowercase for case-insensitive comparison
      const matchesSearch =
        !searchText || // Allow all if searchText is empty
        item.username?.toLowerCase().includes(searchText.toLowerCase()) ||
        item.institutionname?.toLowerCase().includes(searchText.toLowerCase()) ||
        item.remarks?.toLowerCase().includes(searchText.toLowerCase()) ||
        item.status?.toLowerCase().includes(searchText.toLowerCase()) 

      const matchesStatus =
        !status || status.value === "All Status" || item.status === status.value;
      const matchesSchool =
        !school || school.value === "All School" || item.institutionname === school.value;

        const matchesSale =
        !salesPerson || salesPerson.value === "All School" || item.username === salesPerson.value;

      // Combine filters, allowing partial matches if others are not applied
      return matchesSearch && matchesStatus && matchesSchool && matchesSale;
    });

    setTableData(filteredData);
  }, [searchText, school, status, salesPerson]);

  const fetchData = async () => {
    try {
      const data = {};
      const result = await post("/getAllAssignedInstitution", data);


      const temp = result.data.map((list) => ({
        salesperson: list.username,
        institution: list.institutionname,
        status: list.status,
        remarks: '-',
        ...list,
      }));


      const statusSet = new Set(result.data.map((item) => item.status));
      const institutionSet = new Set(result.data.map((item) => item.institutionname));
      const salesPersonSet = new Set(result.data.map((item) => item.username));


      const statusArray = [
        { label: "All Status", value: "All Status" },
        ...Array.from(statusSet).map((status) => ({
          label: status,
          value: status,
        })),
      ];

      const schoolArray = [
        { label: "All School", value: "All School" },
        ...Array.from(institutionSet).map((institu) => ({
          label: institu,
          value: institu,
        })),
      ];

      const salesArray = [
        { label: "All Sales Person", value: "All Sales Person" },
        ...Array.from(salesPersonSet).map((sales) => ({
          label: sales,
          value: sales,
        })),
      ];

      // Update states
      setStatusOptions(statusArray);
      setSchoolOptions(schoolArray);
      setSalesOptions(salesArray)
      setTableData(temp);
      setInitialData(temp);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleDelete = async (data) => {
    setShowDeleteModal(true);
    setDeleteItem(data);
  };

  const handleCloseModal = async () =>{
    setShowDeleteModal(false);
  }

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor("slNo", {
      header: "Sl. No",
      cell: (info) => info.row.index + 1,
    }),
    columnHelper.accessor("salesperson", {
      header: "SALES PERSON",
      cell: ({ row }) => (
        <div className="flex items-center">
          <span className="material-symbols-outlined w-8 h-8 mr-3 rounded-full flex items-center justify-center bg-gray-200">
            person
          </span>
          <span>{row.original.salesperson}</span>
        </div>
      ),
    }),

    columnHelper.accessor("institution", {
      header: "INSTITUTION",
    }),
    columnHelper.accessor("status", {
      header: "STATUS",
    }),
    columnHelper.accessor("remarks", {
      header: "REMARKS ",
    }),
    columnHelper.accessor("actions", { 
      header: "ACTIONS",
      cell: (info) => (
        <div className="flex gap-2 sm:gap-1 sm:w-[200px] sm:overflow-x-auto sm:pb-2 sm:pl-2">
          <button 
                      onClick={() =>
                        navigate("/edit-assigned-institution", {
                          state: {
                            assignInfo: info.row.original,
                            type: "edit",
                            isEdit: true,
                          },
                        })
                      }
          className="p-2 bg-gray-200 rounded-md">
            <span className="material-symbols-outlined">edit</span>
          </button>
          <button className="p-2 bg-gray-200 text-red-500 rounded-md">
            <span className="material-symbols-outlined" onClick={() => handleDelete(info.row.original)}>delete</span>
          </button>
        </div>
      ),
    }),
  ];

  const data = [
    {
      salesperson: "Yousuff Rahmathullah",
      institution: "St. Joseph",
      status: "Assigned",
      remarks: "-",
    },
    {
      salesperson: "Yousuff Rahmathullah",
      institution: "St. Joseph",
      status: "Assigned",
      remarks: "-",
    },
  ];

  const handleSearch = (e) => {
    setSearchData(e.target.value);
  };

  const handleFormSelection = (e, type) => {
    console.log(e, "eee");
    if (type == "status") {
      if (e.label === "All Status") {
        setStatus("");
      } else {
        setStatus(e);
      }
    } else if(type == "school") {
      if (e.label === "All School") {
        setSchool("");
      } else {
        setSchool(e);
      }
    }
    else {
      if (e.label === "All Sales Person") {
        setSalesPerson("");
      } else {
        setSalesPerson(e);
      }
    }
  };

  const handleConfirmDelete = async () => {
    try {
      const result = await post("/deleteAssignedInstitution", deleteItem);
      toast.success("Institution deleted successfully");
      setShowDeleteModal(false);
      fetchData();
    } catch (error) {
      toast.error("Institution delete failed");
      setShowDeleteModal(false);
    }
  }

  return (
    <div>
      <div className="flex justify-between">
        <div>
          <h2 className="text-primary-black text-[28px] font-semibold">
            Assigned Institution
          </h2>
          <p className="text-[14px]">
            <span className="font-bold">Assigned Institution</span> /{" "}
            <span className="font-regular">Assigned Institution List</span>
          </p>
        </div>
      </div>

      {/* Filter Fields */}
      <div className="grid grid-cols-1 gap-4 mt-4 md:flex md:justify-between">
        <div className="w-full md:w-5/12">
          <input
            type="text"
            className="outline-none p-2 rounded-l-lg w-full h-[40px]"
            placeholder="Search..."
            value={searchText}
            onChange={(e) => handleSearch(e)}
          />
        </div>
        <div className="w-full md:w-auto md:flex md:gap-4">
          <div className="sm:w-full md:w-40 lg:w-52">
          <SearchSelect placeholder="Status"
                    value={status}
                    onChange={(e) => handleFormSelection(e, "status")}
                    options={statusOptions}
             />
          </div>
          <div className="sm:w-full md:w-40 lg:w-52">
            <SearchSelect placeholder="Institution"
                  value={school}
                  onChange={(e) => handleFormSelection(e, "school")}
                  options={schoolOptions}
             />
          </div>
          <div className="sm:w-full md:w-40 lg:w-52">
            <SearchSelect placeholder="Sales Person"
                  value={salesPerson}
                  onChange={(e) => handleFormSelection(e, "salesperson")}
                  options={salesOptions}
             />
          </div>
          {/* <div className="">
            <DatePicker
              placeholder="Select Destination..."
              selectedDate={new Date()}
              onDateChange={() => {}}
            />
          </div> */}
        </div>
      </div>

      {/* Table */}
      <div className="bg-white rounded-xl border-gray-300 mt-7 pb-7">
        <Table columns={columns} data={tableData} />
      </div>

      {/* Delete Confirmation Modal */}
      <ConfirmationModal
        isOpen={showDeleteModal}
        onClose={handleCloseModal}
        onConfirm={handleConfirmDelete}
        title="Delete Insitition"
        message={`Are you sure you want to delete the institution "${deleteItem?.institution}"?`}
      />

      <ToastContainer />
    </div>
  );
};

export default AssignedInstitution
