import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../customComponents/Button";
import Paper from "../widgets/Paper";
import InputText from "../customComponents/InputText";
import Select from "../customComponents/Select";
import Upload from "../customComponents/Upload";
import RadioButton from "../customComponents/RadioButton";
import { post } from "../utils/api";
import Papa from "papaparse";
import { toast, ToastContainer } from "react-toastify";
import UploadCSV from "../customComponents/UploadCSV";

const AddInstitution = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const location = useLocation();
  const navigate = useNavigate();
  const { institution, insDetailsFlag } = location.state || {};
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);
  const [selectedValue, setSelectedValue] = useState("single");
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);
  const [csvUploaded, setCsvUploaded] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    state: "",
    city: "",
    area: "",
    location: "",
    address: "",
    primaryname: "",
    primarydesignation: "",
    primaryemail: "",
    primarymobilenumber: "",
    secondaryname: "",
    secondarydesignation: "",
    secondaryemail: "",
    secondarymobilenumber: "",
  });
  const [locationOptions, setLocationOptions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);

  useEffect(() => {
    // Fetch state options on mount
    const fetchStateOptions = async () => {
      try {
        const response = await post("/getOptionList", { type: "states" });
        setStateOptions(response.data);
      } catch (error) {
        console.error("Error fetching state options:", error);
      }
    };

    fetchStateOptions();
  }, []);

  useEffect(() => {
    if (insDetailsFlag) {
      // const fetchAndSetLocation = async () => {
      //   const payload = {
      //     type: "locations",
      //     cityid: institution.cityid,
      //   };
      //   const result = await post("/getOptionList", payload);
      //   const locationLabel = result.data.find(
      //     (loc) => loc.value === parseInt(institution.locationid)
      //   )?.label;

        setFormData({
          name: institution.name || "",
          state: institution.state || "",
          city: institution.city || "",
          area: institution.area || "",
          location: institution.location || "",
          logo: institution?.logo || "",
          address: institution.address || "",
          primaryname: institution.primary_name || "",
          primarydesignation: institution.primary_designation || "",
          primaryemail: institution.primary_email || "",
          primarymobilenumber: institution.primary_mobile_number || "",
          secondaryname: institution.secondary_name || "",
          secondarydesignation: institution.secondary_designation || "",
          secondaryemail: institution.secondary_email || "",
          secondarymobilenumber: institution.secondary_mobile_number || "",
        });
        setSelectedState({
          value: institution.stateid,
          label: institution.state,
        });
        setSelectedCity({
          value: institution.cityid,
          label: institution.city,
        });
        setSelectedArea({
          value: institution.area,
          label: institution.area,
        });
        setSelectedLocation({
          value: institution.locationid,
          label: institution.location,
        });
      

      fetchCities(institution.stateid);
      fetchLocations(institution.cityid)
    }
  }, [institution]);

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleNavigateBack = () => {
    navigate(-1);
  };

  const handleStateChange = async (option) => {
    setSelectedState(option);
    setFormData({ ...formData, state: option.value, stateLabel: option.label });
    fetchCities(option.value);
    // Fetch city options based on selected state
  };
  const fetchCities = async (stateid) => {
    try {
      const response = await post("/getOptionList", {
        type: "cities",
        stateid: stateid,
      });
      setCityOptions(response.data);
    } catch (error) {
      console.error("Error fetching city options:", error);
    }
  };

  const handleCityChange = async (option) => {
    setSelectedCity(option);
    setFormData({ ...formData, city: option.value, cityLabel: option.label });
    fetchLocations(option.value);
  };

  const fetchLocations = async (cityid) => {
    try {
      const response = await post("/getOptionList", {
        type: "locations",
        cityid: cityid,
      });
      setLocationOptions(response.data);
    } catch (error) {
      console.error("Error fetching city options:", error);
    }
  };

  const handleAreaChange = (option) => {
    setSelectedArea(option);
    setFormData({ ...formData, area: option.value });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name.includes("mobilenumber") && /[^0-9]/.test(value)) {
      return;
    }
    setFormData({ ...formData, [name]: value });
  };

  const formStructure = {
    sections: [
      {
        title: "School Details",
        fields: [
          {
            label: "Name",
            type: "text",
            placeholder: "School Name",
            required: true,
            name: "name",
            maxLength: 250,
          },
          {
            label: "State",
            type: "select",
            options: stateOptions,
            placeholder: "Select State",
            required: true,
            name: "state",
          },
          {
            label: "City",
            type: "select",
            options: cityOptions,
            placeholder: "Select City",
            required: true,
            name: "city",
          },
          {
            label: "Area",
            type: "text",
            options: [],
            placeholder: "Select Area",
            required: true,
            name: "area",
          },
          {
            label: "Location",
            type: "select",
            placeholder: "Select City",
            options: locationOptions,
            required: true,
            name: "location",
            maxLength: 100,
          },
          {
            label: "Address",
            type: "text",
            placeholder: "Address",
            required: true,
            name: "address",
            maxLength: 250,
          },
        ],
      },
      {
        title: "Primary Contact",
        fields: [
          {
            label: "Name",
            type: "text",
            placeholder: "Name",
            required: true,
            name: "primaryname",
            maxLength: 100,
          },
          {
            label: "Designation",
            type: "text",
            placeholder: "Designation",
            required: true,
            name: "primarydesignation",
            maxLength: 150,
          },
          {
            label: "Email",
            type: "text",
            placeholder: "Email",
            required: true,
            name: "primaryemail",
            maxLength: 100,
          },
          {
            label: "Mobile Number",
            type: "text",
            placeholder: "Mobile Number",
            required: true,
            name: "primarymobilenumber",
            maxLength: 20,
          },
        ],
      },
      {
        title: "Secondary Contact",
        fields: [
          {
            label: "Name",
            type: "text",
            placeholder: "Name",
            name: "secondaryname",
            maxLength: 100,
          },
          {
            label: "Designation",
            type: "text",
            placeholder: "Designation",
            name: "secondarydesignation",
            maxLength: 100,
          },
          {
            label: "Email",
            type: "text",
            placeholder: "Email",
            name: "secondaryemail",
            maxLength: 50,
          },
          {
            label: "Mobile Number",
            type: "text",
            placeholder: "Mobile Number",
            name: "secondarymobilenumber",
            maxLength: 20,
          },
        ],
      },
    ],
  };

  const renderField = (field) => {
    switch (field.type) {
      case "text":
        return (
          <div key={field.label} className="">
            <label className="text-[16px] font-semibold mb-4">
              {field.label}{" "}
              {field.required && <span className="text-red-500">*</span>}
            </label>
            <InputText
              name={field.name}
              placeholder={field.placeholder}
              value={formData[field.name] || ""}
              onChange={handleChange}
              maxLength={field.maxLength} // Set maxLength if specified in field structure
            />
          </div>
        );
      case "select":
        return (
          <div key={field.label} className="">
            <label className="text-[16px] font-semibold mb-4">
              {field.label}{" "}
              {field.required && <span className="text-red-500">*</span>}
            </label>
            <Select
              options={field.options}
              value={
                field.name === "state"
                  ? selectedState
                  : field.name === "city"
                  ? selectedCity
                  : field.name === "location"
                  ? selectedLocation
                  : selectedArea
              }
              onChange={
                field.name === "state"
                  ? handleStateChange
                  : field.name === "city"
                  ? handleCityChange
                  : field.name === "location"
                  ? handleLocationChange
                  : handleAreaChange
              }
              placeholder={field.placeholder}
            />
          </div>
        );
      default:
        return null;
    }
  };

  const handleLocationChange = (option) => {
    setSelectedLocation(option);
    setFormData({
      ...formData,
      location: option.value,
      locationLabel: option.label,
    });
  };

  const handleUploadSelect = (file) => {
    if (!file) return;

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setFormData({ ...formData, logo: reader.result });
    };
    reader.onerror = (error) => {
      console.error("Error converting file to Base64:", error);
    };
  };

  const validateFile = (file) => {
    if (!file.type.startsWith("image/")) {
      return "Only image files are allowed.";
    }
    if (file.size > 5 * 1024 * 1024) {
      //  5MB size limit
      return "File size should not exceed 5MB.";
    }
    return null;
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleSave = async () => { 
    // Start saving process
    setIsSaving(true);

    // Validate required fields
    const requiredFields = [
      "name",
      "state",
      "city",
      "address",
      "area",
      "location",
      "primaryname",
      "primarydesignation",
      "primaryemail",
      "primarymobilenumber",
    ];

    for (let field of requiredFields) {
      if (!formData[field]) {
        toast.error(`${field.replace(/([A-Z])/g, " $1")} is required.`);
        setIsSaving(false); // End saving process
        return;
      }
    }

    // Validate emails
    if (!validateEmail(formData.primaryemail)) {
      toast.error("Primary email is invalid.");
      setIsSaving(false); // End saving process
      return;
    }
    if (formData.secondaryemail && !validateEmail(formData.secondaryemail)) {
      toast.error("Secondary email is invalid.");
      setIsSaving(false); // End saving process
      return;
    }
    // If validation passes, proceed with API call
    try {
      console.log(locationOptions, stateOptions, cityOptions, "filesss");
      console.log(selectedCity, selectedLocation, selectedState, "dev");
      const payload = {
        ...formData,
        userid: user?.userid || null,
        // state: formData.stateLabel || formData.state, // Ensure state is included and populated
        city: formData.cityLabel || formData.city, // Ensure city is included and populated
        type: insDetailsFlag ? "update" : "insert",
        cityid: cityOptions.filter(
          (list) => list.label == selectedCity?.label
        )[0].value,
        stateid: stateOptions.filter(
          (list) => list.label == selectedState?.label
        )[0].value,
        locationid: locationOptions.filter(
          (list) => list.label == selectedLocation?.label
        )[0].value,
        cityLabel :selectedCity?.label ,
        locationLabel :selectedLocation?.label,
        stateLabel:selectedState?.label ,

        ...(insDetailsFlag && { institutionid: institution.institutionid }), // Conditionally add institutionid
      };
      const response = await post("/addInstitution", payload);
      toast.success(response?.message || "Institutions Added Successfully");
      setTimeout(() => {
        navigate("/institutions");
      }, 1000);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsSaving(false); // End saving process
    }
  };

  const handleCSVSelect = (file) => {
    if (!file) return;

    Papa.parse(file, {
      header: true,
      complete: (result) => {
        const validationErrors = validateCSVData(result.data);
        if (validationErrors.length > 0) {
          setValidationErrors(validationErrors);
          toast.error("CSV file validation failed. Please check the errors.");
          setCsvData([]); // Clear the preview
          setCsvUploaded(false);
        } else {
          setValidationErrors([]); // Clear any previous errors
          setCsvData(result.data);
          setCsvUploaded(true);
          toast.success("CSV file successfully uploaded.");
        }
      },
      error: (error) => {
        console.error("Error parsing CSV file:", error);
        toast.error("Failed to parse CSV file.");
        setCsvData([]); // Clear the preview
        setCsvUploaded(false);
      },
    });
  };

  const handleBulkSave = async () => {
    try {
      const response = await post("/bulkAddInstitutions", csvData);
      toast.success(response?.message);

      // Delay navigation to allow toast to appear
      setTimeout(() => {
        navigate("/institutions");
      }, 1000);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const validateCSVData = (data) => {
    const errors = [];
    const requiredFields = [
      "School Name",
      "State",
      "City",
      "Area",
      "Location",
      "Address",
      "Name",
      "Designation",
      "Email",
      "Mobile Number",
    ];

    data.forEach((row, index) => {
      requiredFields.forEach((field) => {
        if (!row[field]) {
          errors.push(`Row ${index + 1}: Missing "${field}"`);
        }
      });
    });

    return errors;
  };

  const renderValidationErrors = () => (
    <div className="bg-red-100 border border-red-400 text-red-700 p-4 rounded mt-8">
      <h3 className="font-bold">Validation Errors:</h3>
      <ul className="list-disc pl-5">
        {validationErrors.map((error, index) => (
          <li key={index}>{error}</li>
        ))}
      </ul>
    </div>
  );
  return (
    <div>
      <div className="block md:block lg:flex justify-between">
        <div>
          <h2 className="text-primary-black text-[24px] font-semibold">
            {insDetailsFlag ? "Edit Institutions" : "Add Institutions"}
          </h2>
          <p className="text-[12px]">
            <span className=" font-bold">Institutions</span> /
            <span className="font-regular">
              {insDetailsFlag ? institution?.name : "Add Institutions"}
            </span>
          </p>
        </div>

        <div className="flex gap-4 mt-4 md:mt-0 justify-end">
          {selectedValue === "single" && (
            <Button type="primary" label="Save" onClick={handleSave} />
          )}
          <Button
            type="secondary"
            label="Cancel"
            onClick={handleNavigateBack}
          />
        </div>
      </div>
      <Paper className="my-8 md:pt-2 md:pb-2 lg:pt-6 lg:pb-6 pt-6 pb-6">
        {!insDetailsFlag && (
          <>
            <h1 className="text-[16px] font-semibold">Upload Type</h1>
            <div className="mt-4 space-y-4">
              <RadioButton
                name="customRadio"
                value="single"
                checked={selectedValue === "single"}
                onChange={handleRadioChange}
                label="Single Upload"
              />
              <RadioButton
                name="customRadio"
                value="bulk"
                checked={selectedValue === "bulk"}
                onChange={handleRadioChange}
                label="Bulk Upload"
                className="ml-4"
              />
            </div>
          </>
        )}

        {selectedValue === "single" && (
          <>
            <Paper className="my-8 border">
              <h1 className="text-[20px] font-bold mb-4">School Details</h1>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                <div className="col-span-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {formStructure.sections[0].fields
                    .slice(0, 3)
                    .map((field) => renderField(field))}
                  {formStructure.sections[0].fields
                    .slice(3, 6)
                    .map((field) => renderField(field))}
                </div>
                <div className="mt-3">
                  <Upload
                    onFileSelect={handleUploadSelect}
                    initialPreviewUrl={institution?.logo}
                    labelText="Upload Logo"
                    fileValidation={validateFile}
                  />
                </div>
              </div>
            </Paper>
            <Paper className="my-8 border">
              <h1 className="text-[20px] font-bold mb-4">Primary Contact</h1>
              <div className="grid grid-flow-row-dense grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                {formStructure.sections[1].fields.map((field) =>
                  renderField(field)
                )}
              </div>
            </Paper>
            <Paper className="my-8 border">
              <h1 className="text-[20px] font-bold mb-4">Secondary Contact</h1>
              <div className="grid grid-flow-row-dense grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                {formStructure.sections[2].fields.map((field) =>
                  renderField(field)
                )}
              </div>
            </Paper>
          </>
        )}
        {selectedValue === "bulk" && (
          <Paper className="my-8 border">
            <h1 className="text-[20px] font-bold mb-4">Upload File</h1>
            <UploadCSV
              onFileSelect={handleCSVSelect}
              acceptedFileTypes={"text/csv"}
              labelText="Upload CSV"
            />
            {validationErrors.length > 0 && renderValidationErrors()}
            {csvUploaded && (
              <Button
                type="primary"
                label="Upload Bulk"
                onClick={handleBulkSave}
              />
            )}
            {csvData.length > 0 && (
              <Paper className="my-4 border">
                <h1 className="text-[20px] font-bold mb-4">CSV Data Preview</h1>
                <table className="w-full border-collapse">
                  <thead>
                    <tr>
                      {Object.keys(csvData[0] || {}).map((key) => (
                        <th key={key} className="border p-2">
                          {key}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {csvData.map((row, index) => (
                      <tr key={index}>
                        {Object.values(row).map((value, i) => (
                          <td key={i} className="border p-2">
                            {value}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Paper>
            )}
          </Paper>
        )}
      </Paper>
      <ToastContainer />
    </div>
  );
};

export default AddInstitution;
