import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

const MultiSelect = ({ options, placeholder, label }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const multiSelectRef = useRef(null);

  const handleSelect = (option) => {
    // Toggle option in the selectedOptions array
    const isAlreadySelected = selectedOptions.find((item) => item.value === option.value);
    const newSelection = isAlreadySelected
      ? selectedOptions.filter((item) => item.value !== option.value) // Remove if selected
      : [...selectedOptions, option]; // Add if not selected

    setSelectedOptions(newSelection);
  };

  const handleClickOutside = (event) => {
    if (multiSelectRef.current && !multiSelectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={multiSelectRef}>
      {label && (
        <label className="block text-sm font-medium text-gray-700 mb-1">
          {label}
        </label>
      )}
      <div
        className="h-[40px] md:h-[32px] mt-2 bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 md:py-1 text-left cursor-pointer focus:outline-none focus:ring-1 border-b-2 border-b-[#797979] focus:ring-blue-500 focus:border-blue-500"
        onClick={() => setIsOpen(!isOpen)}
        style={{ fontSize: "14px", color: "#797979" }}
      >
        <span className="block truncate">
          {selectedOptions.length > 0
            ? selectedOptions.map((option) => option.label).join(", ")
            : placeholder}
        </span>
        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <svg
            className="h-5 w-5 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M10 12a1 1 0 01-.707-.293l-6-6a1 1 0 011.414-1.414L10 9.586l5.293-5.293a1 1 0 011.414 1.414l-6 6A1 1 0 0110 12z"
              clipRule="evenodd"
            />
          </svg>
        </span>
      </div>
      {isOpen && (
        <div className="absolute mt-1 w-full rounded-md bg-white shadow-lg z-10">
          <div className="max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
            {options.length === 0 ? (
              <div className="text-gray-500 py-2 pl-3 pr-9">No data found</div>
            ) : (
              options.map((option) => (
                <div
                  key={option.value}
                  className={`text-gray-900 cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-gray-100 ${
                    selectedOptions.find((item) => item.value === option.value)
                      ? "bg-blue-100"
                      : ""
                  }`}
                  onClick={() => handleSelect(option)}
                  style={{ fontSize: "14px", color: "#797979" }}
                >
                  <span className="block truncate">{option.label}</span>
                  {selectedOptions.find((item) => item.value === option.value) && (
                    <span className="absolute inset-y-0 right-0 flex items-center pr-3 text-blue-600">
                      <svg
                        className="h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 00-1.414 0L7 13.586 4.707 11.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l9-9a1 1 0 000-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  )}
                </div>
              ))
            )}
          </div>
        </div>
      )}
    </div>
  );
};

MultiSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  placeholder: PropTypes.string,
  label: PropTypes.string,
};

MultiSelect.defaultProps = {
  options: [],
  placeholder: "Select...",
  label: null,
};

export default MultiSelect;
