import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

const Select = ({ options, value, onChange, placeholder, label, disabled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);

  const handleSelect = (option) => {
    if (!disabled) {
      onChange(option);
      setIsOpen(false);
    }
  };

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleClear = () => {
    if (!disabled) {
      onChange(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="" ref={selectRef}>
      {label && (
        <label className="block text-sm font-medium text-gray-700 mb-1">
          {label}
        </label>
      )}
      <div className="relative">
        <div
          className={`h-[40px] bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 md:py-2 text-left ${
            disabled ? "cursor-not-allowed bg-gray-200" : "cursor-pointer"
          } focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 z-10`}
          onClick={() => !disabled && setIsOpen(!isOpen)}
          style={{ fontSize: "14px", color: disabled ? "#a3a3a3" : "#797979" }}
        >
          <span className="block truncate">
            {value ? value.label : placeholder}
          </span>

          {/* clear button for FOC */}
          {placeholder === 'Select FOC...' && (
            <span className="absolute inset-y-0 right-12 flex items-center">
              <svg
                onClick={handleClear} height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
            </span>
          )}
           {/* clear button for FOC */}

          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <svg
              className="h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M10 12a1 1 0 01-.707-.293l-6-6a1 1 0 011.414-1.414L10 9.586l5.293-5.293a1 1 0 011.414 1.414l-6 6A1 1 0 0110 12z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </div>
        {isOpen && (
          <div className="absolute mt-1 w-full rounded-md bg-white shadow-lg z-10">
            <div className="max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
              {options.length === 0 ? (
                <div className="text-gray-500 py-2 pl-3 pr-9">
                  No data found
                </div>
              ) : (
                options?.map((option) => (
                  <div
                    key={option?.value}
                    className="text-gray-900 cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-gray-100"
                    onClick={() => handleSelect(option)}
                    style={{ fontSize: "14px", color: "#797979" }}
                  >
                    <span className="block truncate">{option.label}</span>
                  </div>
                ))
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  value: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

Select.defaultProps = {
  options: [],
  placeholder: "Select...",
  label: null,
  disabled: false,
};

export default Select;