import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import LoginBg from "../assets/img/login-bg.svg";
import Password from "../customComponents/Password";
import Loginsmall from "../assets/img/login-mob.svg";
import { post } from "../utils/api";

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { optionalParam } = useParams();

  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");
  const [newPassword, setNewPassword] = useState("");

  useEffect(() => {
    let match = optionalParam && optionalParam.match(/(?<=key\=)(?:.*)/);
    if (match && match.length > 0) {
      let extractedValue = atob(match[0]);
      let userData = JSON.parse(extractedValue);
      setUserName(userData.username);
      setEmail(userData.email);
      setUserId(userData.userid);
    } else {
    }
  }, [location]);

  const handleSubmit = async () => {
    try {
      const payload = {
        userId: userId,
        oldPassword: "",
        // email: email,
        newPassword: newPassword,
        confirmPassword: newPassword,
      };
      const response = await post("/updateUserPassword", payload);
      console.log("Password updated successfully:", response);
      navigate("/login");
    } catch (error) {
      console.error("Error updating password:", error);
    }
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4 bg-white login-div overflow-auto md:overflow-auto lg:overflow-hidden">
      <div className="hidden lg:block">
        <img
          src={LoginBg}
          className="rounded rounded-r-2xl"
          alt="Login background"
        />
      </div>
      <div className="lg:hidden">
        <img
          src={Loginsmall}
          className="w-full h-full object-cover"
          alt="Login background"
        />
      </div>
      <div>
        <div className="mt-16 flex flex-col items-center justify-center">
          <img
            src="https://crm.fuelifeexplorers.com/crmImages/logo/1680004129Fuelife-Explorers.png"
            className="react h-[80px]"
            alt="React logo"
          />
        </div>
        <div className="px-20 ">
          <h1 className="text-[28px] text-[#121212] font-semibold my-4">
            Reset Password
          </h1>
          <p className="mb-6 text-[14px] md:text-[14px] lg:text-[16px]">
            Provide your account’s email for which you want to reset your
            password?
          </p>
          {/* <div className="text-left mb-4">
            <label className="text-[16px] font-semibold">Email</label>
            <InputText value={email} placeholder="Email" readOnly />
          </div> */}
          <label className="text-[14px] md:text-[16px] font-semibold mb-4">
            New Password
          </label>
          <Password
            type="password"
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className="border-b-2 bg-white border text-mute-text border-gray-300 rounded-md h-[32px] w-full mt-2 text-sm p-2 placeholder:text-sm border-b-[#797979]"
          />
          <button
            type="button"
            className="w-56 bg-red-500 text-white py-2 rounded-3xl shadow-lg hover:bg-red-600 mb-6"
            onClick={handleSubmit}
          >
            Submit
          </button>

          <div className="items-center mb-6">
            <a href="/login" className="text-[#121212] hover:underline">
              Go back to Login
            </a>
          </div>
          <div className="text-center mt-20 md:mt-20 lg:mt-36 mb-4">
            <p className="text-[#797979] text-[14px]">
              Copyright @ Fuelife 2024
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
