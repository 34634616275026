import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../customComponents/Button";
import Paper from "../widgets/Paper";
import InputText from "../customComponents/InputText";
import Select from "../customComponents/Select"; // Assuming there's a single-select component
// import MultiSelect from "../customComponents/MultiSelect";
import Upload from "../customComponents/Upload";
import Loader from "../widgets/Loader";
import { post } from "../utils/api";
import { toast, ToastContainer } from "react-toastify";
import { MultiNewSelect } from "../customComponents/NewMultiSelect";
import SearchSelect from "../customComponents/SerachSelect";


const AddUser = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, type, isEdit } = location.state || {};
  const [loading, setLoading] = useState(false);

  const [formState, setFormState] = useState({
    username: "",
    password: "",
    role: "",
    email: "",
    mobilenumber: "",
    assigncity: null,
    location: [],
    institution: [],
    profilephoto: null,
    isactive: true,
    type: type || "insert",
    userid: user?.userid || null,
  });

  const [locations, setLocations] = useState([]);
  const [institutions, setInstitutions] = useState([]);
  const [dropdownDataLoaded, setDropdownDataLoaded] = useState(false);
  const [cityOptions, setCityOptions] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedLocations, setSelectedLocations] = useState("");
  const [selectedInstitutions, setSelectedInstitutions] = useState("");
  const [insOptions, setInsOptions] = useState([]);
  const [isMailStatus, setMailStatus] = useState(false);
  const [mailMsg, setMailMsg] = useState('')


  const cityData = {
    Bangalore: {
      locations: [
        { id: 1, value: "HSR", label: "HSR" },
        { id: 2, value: "Koramangala", label: "Koramangala" },
      ],
      institutions: [
        { id: 1, value: "HSR Institution", label: "HSR Institution" },
        {
          id: 2,
          value: "Koramangala Institution",
          label: "Koramangala Institution",
        },
      ],
    },
    Chennai: {
      locations: [
        { id: 1, value: "Adyar", label: "Adyar" },
        { id: 2, value: "T Nagar", label: "T Nagar" },
      ],
      institutions: [
        { id: 1, value: "Adyar Institution", label: "Adyar Institution" },
        { id: 2, value: "T Nagar Institution", label: "T Nagar Institution" },
      ],
    },
    Mumbai: {
      locations: [
        { id: 1, value: "Andheri", label: "Andheri" },
        { id: 2, value: "Bandra", label: "Bandra" },
      ],
      institutions: [
        { id: 1, value: "Andheri Institution", label: "Andheri Institution" },
        { id: 2, value: "Bandra Institution", label: "Bandra Institution" },
      ],
    },
  };
  console.log(selectedCity, "selectecity");

  useEffect(() => {
    const fetchAllOptions = async () => {
      try {
        await Promise.all([
          fetchCities(),
          fetchInstitutions(),
          fetchLocations("",false)

          // fetchHotelTypes(),
          // fetchOnwardTransport(),
        ]);
        setDropdownDataLoaded(true); // Mark options as loaded
      } catch (error) {
        console.error("Error fetching dropdown options:", error);
      }
    };

    fetchAllOptions();
  }, []);

  useEffect(() => {
    if (isEdit && dropdownDataLoaded) {
      prePopulateValue(); // Now dropdown options are ready
    }
  }, [dropdownDataLoaded]);

  // useEffect(() => {
  //   fetchLocations("");
  // }, [isEdit]);

  console.log("location", location);

  const prePopulateValue = () => {
    const selected = cityOptions.find(
      (item) => item.id === parseInt(user?.cityid)
    );
    setSelectedCity(selected);

    const userLocationIds = user?.locationid
      ? Array.isArray(user.locationid)
        ? user.locationid
        : user.locationid.replace(/[{}]/g, "").split(",")
      : [];

    const location = locations.filter((list) =>
      userLocationIds.includes(list.locationid.toString())
    );
    setSelectedLocations(location);

    const userInstitutionIds = user?.institutionid
      ? Array.isArray(user.institutionid)
        ? user.institutionid
        : user.institutionid.replace(/[{}]/g, "").split(",")
      : [];

    const institution = insOptions.filter((list) =>
      userInstitutionIds.includes(list.institutionid.toString())
    );
    setSelectedInstitutions(institution);
  };

  const fetchCities = async () => {
    try {
      const payload = { type: "source" };
      const result = await post("/getOptionList", payload);

      setCityOptions(result.data);
    } catch (error) {
      console.error("Error fetching package types:", error);
    }
  };

  const fetchLocations = async (id, isFetchLocation) => {
     if(isEdit || isFetchLocation){
    try {
      const payload = { type: "locations", cityid: id };
      const result = await post("/getOptionList", payload);

      setLocations(result.data);
    } catch (error) {
      console.error("Error fetching package types:", error);
       }
    }
  };

  const fetchInstitutions = async (e) => {
    try {
      const payload = { type: "institution" };
      const result = await post("/getOptionList", payload);
      // let filteredData = result.data.filter(item =>
      //   e.some(d => d.id === item.locationid)
      // );
      //  setInstitutions(filteredData);
      setInsOptions(result.data);
    } catch (error) {
      console.error("Error fetching package types:", error);
    }
  };

  const handleSelectChange = (value, name) => {
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "assigncity") {
      loadLocations(value?.value);
      setFormState((prevState) => ({
        ...prevState,
        location: [],
        institution: [],
      }));
    }

    if (name === "location") {
      loadInstitutions(value.map((v) => v.value));
      setFormState((prevState) => ({ ...prevState, institution: [] }));
    }
  };

  const handleFormSelection = (e, type) => {
    if (type == "assigncity") {
      const selected = cityOptions.find((item) => item.id === e.id);
      setSelectedCity(selected);
      fetchLocations(e.id, true);
    } else if (type == "locations") {
      setSelectedLocations(e);

      let filteredData = insOptions.filter((item) =>
        e.some((d) => d?.locationid === parseInt(item?.locationid))
      );
      setInstitutions(filteredData);
    } else {
      setSelectedInstitutions(e);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "email") {
      // Allow only valid email characters
      const validEmailChars = /^[a-zA-Z0-9._%+-@]*$/;
      if (validEmailChars.test(value)) {
        setFormState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    } else if (name === "mobilenumber") {
      // Allow only numeric input for mobile number
      const validNumberChars = /^[0-9]*$/;
      if (validNumberChars.test(value)) {
        setFormState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const loadLocations = (city) => {
    const cityInfo = cityData[city];
    if (cityInfo) {
      // setLocations(cityInfo.locations);
      // setInstitutions(cityInfo.institutions);
    } else {
      // setLocations([]);
      // setInstitutions([]);
    }
  };

  const loadInstitutions = (selectedLocations) => {
    const city = formState.assigncity?.value;
    if (city) {
      const cityInfo = cityData[city];
      if (cityInfo) {
        //  setInstitutions(cityInfo.institutions);
      }
    }
  };

  useEffect(() => {
    if (user) {
      setFormState({
        ...user,
        password: "",
        type: "update",
        role: { value: user.role, label: user.role },
        assigncity: { value: user.assigncity, label: user.assigncity },
        isactive: user.isactive ?? true,
      });
      loadLocations(user.assigncity);
    }
  }, [user]);

  const handleFileSelect = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setFormState((prevState) => ({
        ...prevState,
        profilephoto: reader.result,
      }));
    };
    reader.readAsDataURL(file);
  };

  const validateFields = () => {
    if (!formState.username?.trim()) {
      toast.error("Username is required");
      return false;
    }

    if (!formState.role?.value) {
      toast.error("Designation is required");
      return false;
    }

    if (!formState.email?.trim()) {
      toast.error("Email is required");
      return false;
    }

    // Email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formState.email)) {
      toast.error("Please enter a valid email address");
      return false;
    }

    if (!formState.mobilenumber?.trim()) {
      toast.error("Mobile number is required");
      return false;
    }

    // Mobile number validation (10 digits)
    if (!/^\d{10}$/.test(formState.mobilenumber)) {
      toast.error("Mobile number must be 10 digits");
      return false;
    }

    // Validate institution section fields if role requires them
    // if (shouldShowInstitutionSection(formState.role)) {
    //   if (!selectedCity) {
    //     toast.error("City is required");
    //     return false;
    //   }

    //   if (
    //     !selectedLocations ||
    //     (Array.isArray(selectedLocations) && selectedLocations.length === 0)
    //   ) {
    //     toast.error("Please select at least one location");
    //     return false;
    //   }

    //   if (
    //     !selectedInstitutions ||
    //     (Array.isArray(selectedInstitutions) &&
    //       selectedInstitutions.length === 0)
    //   ) {
    //     toast.error("Please select at least one institution");
    //     return false;
    //   }
    // }

    return true;
  };

  const handleSave = async () => {
    if(isMailStatus){
    if (!validateFields()) {
      return;
    }

    setLoading(true);
    try {
      const locationIds = Array.isArray(selectedLocations)
        ? `{${selectedLocations
            .map((location) => location?.locationid)
            .join(",")}}`
        : selectedLocations?.id
        ? `{${selectedLocations.id}}`
        : null;

      const institutionIds = Array.isArray(selectedInstitutions)
        ? `{${selectedInstitutions
            .map((institution) => institution?.institutionid)
            .join(",")}}`
        : selectedInstitutions?.id
        ? `{${selectedInstitutions.id}}`
        : null;

      const payload = {
        ...formState,
        role: formState?.role?.value,
        type: formState.userid ? "update" : "insert",
        isactive: formState.isactive || true,
        locationid: locationIds,
        institutionid: institutionIds,
        assigncity: selectedCity?.label,
        cityid: selectedCity?.id,
      };

      const response = await post("/addUser", payload);
      if (response.status) {
        toast.success(
          formState.userid
            ? "User updated successfully"
            : "User added successfully"
        );
        setTimeout(() => navigate("/users"), 1500);
      } else {
        toast.error("Operation failed. Please try again.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  }else{
    toast.error('Email already exist!')
  }
  };

  const handleNavigateBack = () => {
    navigate(-1);
  };

  const shouldShowInstitutionSection = (role) => {
    const salesRoles = ["Sales Manager", "Sales Executive", "Admin"];
    return salesRoles.includes(role?.value);
  };

  
  const handleBlur = async (e) => {
    console.log(e.target.value, 'value');

    const payload = {
      email: e.target.value
    };
    const response = await post("/checkMailAvailability", payload);
    setMailStatus(response.status);
    setMailMsg(response.message);

  }

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="flex justify-between flex-wrap">
            <div>
              <h2 className="text-primary-black text-[24px] font-semibold">
                Add User
              </h2>
              <p className="text-[12px]">
                <span className="font-bold">Users</span> /{" "}
                <span className="font-regular">Add User</span>
              </p>
            </div>
            <div className="flex gap-4 flex-wrap">
              <Button
                type="primary"
                label={isEdit ? "Update" : "Save"}
                onClick={handleSave}
              />
              <Button
                type="secondary"
                label="Cancel"
                onClick={handleNavigateBack}
              />
            </div>
          </div>

          <Paper className="my-8 py-12 border">
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
              {/* Profile Photo Upload */}
              <div>
                <Upload onFileSelect={handleFileSelect} />
              </div>

              {/* User Information */}
              <div className="col-span-2 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                <div>
                  <label className="text-[16px] font-semibold mb-2 block">
                    User Name <span className="text-red-500">*</span>
                  </label>
                  <InputText
                    name="username"
                    value={formState.username}
                    onChange={handleInputChange}
                    placeholder="User Name"
                  />
                </div>
                <div>
                  <label className="text-[16px] font-semibold mb-4 block">
                    Designation <span className="text-red-500">*</span>
                  </label>
                  <SearchSelect
                    name="role"
                    value={formState.role}
                    onChange={(selectedOption) =>
                      handleSelectChange(selectedOption, "role")
                    }
                    options={[
                      { value: "Sales Manager", label: "Sales Manager" },
                      { value: "Sales Executive", label: "Sales Executive" },
                      { value: "Backend Team", label: "Backend Team" },
                      {
                        value: "Backend Team Manager",
                        label: "Backend Team Manager",
                      },
                    ]}
                    placeholder="Select Designation"
                  />
                </div>
                <div>
                  <label className="text-[16px] font-semibold mb-2 block">
                    Email <span className="text-red-500">*</span>
                  </label>
                  <InputText
                    name="email"
                    value={formState.email}
                    onChange={handleInputChange}
                    placeholder="Email"
                    onBlur={(e) => handleBlur(e)}
                  />
                {!isMailStatus ? <span className="error">{mailMsg}</span> : null}

                </div>
                <div>
                  <label className="text-[16px] font-semibold mb-2 block">
                    Mobile Number <span className="text-red-500">*</span>
                  </label>
                  <InputText
                    name="mobilenumber"
                    value={formState.mobilenumber}
                    onChange={handleInputChange}
                    placeholder="Mobile Number"
                  />
                </div>

                {/* Assign Institution Section */}
                {shouldShowInstitutionSection(formState.role) && (
                  <>
                    <div className="col-span-full pt-4">
                      <h3 className="text-[20px] font-bold">
                        Assign Institution
                      </h3>
                    </div>

                    <div>
                      <label className="text-[16px] font-semibold mb-2 block">
                        Assign City
                      </label>
                      <SearchSelect
                        name="assigncity"
                        value={cityOptions.find(
                          (option) => option.value === selectedCity?.id
                        )}
                        onChange={(e) => handleFormSelection(e, "assigncity")}
                        options={cityOptions}
                        placeholder="Select Location"
                      />
                    </div>
                    <div>
                      <label className="text-[16px] font-semibold mb-2 block">
                        Location
                      </label>
                      <MultiNewSelect
                        placeHolder="Select Location(s)"
                        options={locations}
                        value={selectedLocations}
                        onChange={(e) => handleFormSelection(e, "locations")}
                        isMulti
                        id="fileTypes"
                      />
                    </div>
                    <div>
                      <label className="text-[16px] font-semibold mb-2 block">
                        Institution
                      </label>
                      <MultiNewSelect
                        placeHolder="Select Institution(s)"
                        options={institutions}
                        value={selectedInstitutions}
                        onChange={(e) => handleFormSelection(e, "institutions")}
                        isMulti
                        id="fileTypes"
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </Paper>
          <ToastContainer />
        </div>
      )}
    </div>
  );
};

export default AddUser;
