import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

const SearchSelect = ({
  options,
  value,
  onChange,
  placeholder,
  label,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);
  const selectRef = useRef(null);

  const handleSelect = (option) => {
    if (!disabled) {
      onChange(option);
      setIsOpen(false);
      setSearchTerm("");
    }
  };

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const filtered = options?.filter((option) =>
      option?.label?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredOptions(filtered);
  }, [searchTerm, options]);

  return (
    <div className="relative" ref={selectRef}>
      {label && (
        <label className="block text-sm font-medium text-gray-700 mb-1">
          {label}
        </label>
      )}
      <div
        className={`h-[40px]  bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 md:py-1 text-left ${
          disabled ? "cursor-not-allowed bg-gray-200" : "cursor-pointer"
        } focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500`}
        onClick={() => !disabled && setIsOpen(!isOpen)}
        style={{ fontSize: "14px", color: disabled ? "#a3a3a3" : "#797979" }}
      >
        <span className="block truncate pt-1">
          {value ? value.label : placeholder}
        </span>
        <span className="absolute inset-y-0 right-0 flex items-center pt-1 pr-2 pointer-events-none">
          <svg
            className="h-5 w-5 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M10 12a1 1 0 01-.707-.293l-6-6a1 1 0 011.414-1.414L10 9.586l5.293-5.293a1 1 0 011.414 1.414l-6 6A1 1 0 0110 12z"
              clipRule="evenodd"
            />
          </svg>
        </span>
      </div>
      {isOpen && (
        <div className="absolute mt-1 w-full rounded-md bg-white shadow-lg z-10">
          <div className="max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
            <div className="p-2">
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search..."
                className="w-full p-2 border border-gray-300 rounded-md focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                style={{ fontSize: "14px", color: "#797979" }}
              />
            </div>
            {filteredOptions.length === 0 ? (
              <div className="text-gray-500 py-2 pl-3 pr-9">
                No options found
              </div>
            ) : (
              filteredOptions.map((option) => (
                <div
                  key={option.value}
                  className="text-gray-900 cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-gray-100"
                  onClick={() => handleSelect(option)}
                  style={{ fontSize: "14px", color: "#797979" }}
                >
                  <span className="block truncate">{option.label}</span>
                </div>
              ))
            )}
          </div>
        </div>
      )}
    </div>
  );
};

SearchSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  value: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

SearchSelect.defaultProps = {
  options: [],
  placeholder: "Select...",
  label: null,
  disabled: false,
};

export default SearchSelect;