// File: ../../customComponents/AddonModal.jsx
import React from "react";
import Button from "../customComponents/Button";

const AddonModal = ({ onClose, addonData }) => {
  if (!addonData) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-t-lg shadow-lg w-full h-[50vh] md:h-[75vh] overflow-y-auto p-6 border-b mt-auto">
        {/* Header */}
        <div className="flex justify-between items-center mb-6 border-b">
          <h2 className="text-xl font-semibold">Addon Details</h2>
          <div className="flex space-x-4 items-center">
            <Button type="primary" label="Edit Addon" />
            <a
              onClick={onClose}
              className="text-gray-500 hover:text-black cursor-pointer"
            >
              <span className="material-symbols-outlined">close</span>
            </a>
          </div>
        </div>

        {/* Addon Details Grid */}
        <div className="mt-2 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 pb-6 border-b">
          <div className="mt-7">
            <div className="font-semibold">Addon Item</div>
            <div className="mt-2">
              <div>{addonData.addonitem || "N/A"}</div>
            </div>
          </div>
          <div className="mt-7">
            <div className="font-semibold">Price Per Head</div>
            <div className="mt-2">
              <div>{addonData.addonprice || "N/A"}</div>
            </div>
          </div>
          <div className="mt-7">
            <div className="font-semibold">Season</div>
            <div className="mt-2">
              <div>{addonData.season || "N/A"}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddonModal;
