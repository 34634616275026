import React, { useState } from "react";

const UploadHotel = () => {
  const [fileName, setFileName] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      setFileName(file.name);
    }
  };

  return (
    <div className="flex flex-col items-start w-full">
      <div className="mb-4 text-left">
        <p className="text-sm text-gray-600">
          Upload only .csv file with the format given.{" "}
          <a href="" className="text-blue-600 underline">
            Sample file
          </a>
        </p>
      </div>

      {/* Upload Section */}
      <div className="border-2 border-dashed border-#121212-300 py-6 px-4 rounded-lg text-center relative   w-60 h-40 flex flex-col items-center justify-center">
        <input
          type="file"
          accept=".csv"
          id="hotel-upload"
          className="hidden"
          onChange={handleFileChange}
        />
        <label
          htmlFor="hotel-upload"
          className="cursor-pointer flex flex-col items-center justify-center"
        >
          <div className="text-gray-800 border border-gray-800 rounded-md px-6 py-2 text-sm font-semibold">
            Browse File
          </div>
        </label>
        {fileName && (
          <p className="mt-2 text-sm text-gray-600">{fileName}</p>
        )}
      </div>
    </div>
  );
};

export default UploadHotel;
