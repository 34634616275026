import Button from "../customComponents/Button";
import Paper from "../widgets/Paper";
import InputText from "../customComponents/InputText";
import Select from "../customComponents/Select";
import { useEffect, useState } from "react";
import UploadHotel from "../customComponents/UploadHotel";
import RadioButton from "../customComponents/RadioButton";
import DatePicker from "../customComponents/DatePicker";
import { post } from "../utils/api";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../widgets/Loader";
import { ToastContainer, toast } from "react-toastify";
import SearchSelect from "../customComponents/SerachSelect";


const localOptions = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
];

const AddTransportation = () => {
  const location = useLocation();
  const editData = location.state?.transportData;
  const isEdit = location.state?.isEdit;

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedValue, setSelectedValue] = useState("single");
  const [showSeasonalFields, setShowSeasonalFields] = useState(false);
  const [localOption, setLocalOption] = useState(null);
  const [acOption, setAcOption] = useState(null);
  const [vehicleTypeOption, setVehicleTypeOption] = useState(null);
  const [showInterStateTax, setShowInterStateTax] = useState(true); // New state to toggle visibility
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState([]); // New state to toggle
  const [formValues, setFormValues] = useState({
    vehicleName: editData?.vehicalname || "",
    noofSeats: editData?.noofseats || "",
    city: editData?.city || "",
    destination: editData?.destination || "",
    baseCharge: editData?.basechargeinr || "",
    totalCharge: editData?.totalcharge || "",
    baseKM: editData?.basekm || "",
    additionalKM: editData?.additionalkm || "",
    additionalCharge: editData?.additionalchargeinr || "",
    interStateTax: editData?.interstatetax || "",
    bata: editData?.batainr || "",
    parking: editData?.parking || "",
    pricePerHead: editData?.priceperhead || "",
    seasonalPrice: editData?.seasonalprice || "",
  });
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  console.log(formValues, "formValues");
  console.log(vehicleTypeOptions, "vehicleTypeOptions");
  console.log(vehicleTypeOption, "vehicleTypeOption");

  console.log(acOption, "acOption");
  console.log(localOptions, "localOptions");
  console.log(localOption, "localOption");

  useEffect(() => {
    fetchVehicleType();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value, // Dynamically update the field value
    }));
  };

  const fetchVehicleType = async () => {
    try {
      const payload = { type: "vehicaltype" };
      const result = await post("/getOptionList", payload);
      const options = [
        ...result.data.map((list) => ({
          label: list.label,
          value: list.label,
          id: list.vehicaltypeid,
        })),
      ];
      setVehicleTypeOptions(options);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleDateChange = (type, date) => {
    if (type == "startdate") {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  };

  const handleLocalChange = (option) => {
    setLocalOption(option);
    updateTaxFieldVisibility(option, vehicleTypeOption); // Check visibility on change
  };

  const handleVehicleTypeChange = (option) => {
    setVehicleTypeOption(option);
    updateTaxFieldVisibility(localOption, option); // Check visibility on change
  };

  const updateTaxFieldVisibility = (local, vehicleType) => {
    const shouldShowTax = vehicleType?.value === "Bus" && local?.value === "no";
    setShowInterStateTax(shouldShowTax);

    if (!shouldShowTax) {
      setFormValues((prev) => ({
        ...prev,
        interStateTax: "",
      }));
    }
  };

  const isTrainSelected = vehicleTypeOption?.value === "Train"; // Check if Train is selected
  const isFlightSelected = vehicleTypeOption?.value === "Flight"; // Check if Flight is selected

  const handleAcChange = (option) => {
    setAcOption(option);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      // Common validations for all vehicle types
      if (!vehicleTypeOption?.label)
        throw new Error("Vehicle type is required.");
      if (!formValues.vehicleName || formValues.vehicleName.trim() === "")
        throw new Error("Vehicle name is required.");
      if (!formValues.noofSeats)
        throw new Error("Number of seats is required.");
      if (!formValues.city || formValues.city.trim() === "")
        throw new Error("City is required.");
      if (!formValues.destination || formValues.destination.trim() === "")
        throw new Error("Destination is required.");
      if (!formValues.baseCharge) throw new Error("Base charge is required.");
      if (!formValues.pricePerHead)
        throw new Error("Price per head is required.");

      // Vehicle specific validations
      if (!isFlightSelected && !isTrainSelected) {
        // Validations for regular vehicles (Bus, Car etc.)
        if (!acOption?.label) throw new Error("AC type is required.");
        if (!localOption?.label)
          throw new Error("Local INR option is required.");
        if (!formValues.baseKM) throw new Error("Base KM is required.");
        if (!formValues.bata) throw new Error("Bata charge is required.");
        if (!formValues.parking) throw new Error("Parking charge is required.");

        // Interstate tax validation for Bus type
        if (
          vehicleTypeOption?.value === "Bus" &&
          localOption?.value === "no" &&
          !formValues.interStateTax
        ) {
          throw new Error("Interstate tax is required for non-local bus.");
        }
      }

      // Seasonal price validations
      if (showSeasonalFields) {
        if (!startDate) throw new Error("Season start date is required.");
        if (!endDate) throw new Error("Season end date is required.");
        if (!formValues.seasonalPrice)
          throw new Error("Seasonal price is required.");
      }

      const payload = {
        vehicaltypeid: vehicleTypeOptions.find(
          (list) => list.label === vehicleTypeOption?.label
        )?.id,
        actype: !isFlightSelected && !isTrainSelected ? acOption?.label : null,
        localinr:
          !isFlightSelected && !isTrainSelected ? localOption?.label : null,
        vehicalname: formValues.vehicleName,
        noofseats: formValues.noofSeats,
        destination: formValues.destination,
        city: formValues.city,
        basekm:
          !isFlightSelected && !isTrainSelected ? formValues.baseKM : null,
        basechargeinr: formValues.baseCharge,
        additionalkm: formValues.additionalKM,
        additionalchargeinr: formValues.additionalCharge,
        priceperhead: formValues.pricePerHead,
        interstatetax: formValues.interStateTax,
        batainr: !isFlightSelected && !isTrainSelected ? formValues.bata : null,
        parking:
          !isFlightSelected && !isTrainSelected ? formValues.parking : null,
        seasonstart: showSeasonalFields ? startDate : null,
        seasonend: showSeasonalFields ? endDate : null,
        seasonalprice: showSeasonalFields ? formValues.seasonalPrice : null,
        totalcharge: formValues.totalCharge,
        type: isEdit ? "update" : "insert",
        transportid: editData?.transportid,
      };

      await post("/addTransport", payload);
      toast.success(
        isEdit ? "Vehicle updated successfully!" : "Vehicle added successfully!"
      );
      navigate("/transportation");
    } catch (error) {
      toast.error(
        error.message ||
          (isEdit ? "Failed to update vehicle" : "Failed to add vehicle")
      );
    } finally {
      setLoading(false);
    }
  };

  // Add this function before the return statement
  const calculateTotalCost = () => {
    const {
      baseCharge,
      additionalKM,
      additionalCharge,
      bata,
      parking,
      interStateTax,
      noofSeats,
      // seasonalPrice,
    } = formValues;

    // Convert string values to numbers
    const days = 1;
    const baseCharges = Number(baseCharge);
    const addKms = Number(additionalKM);
    const addCharges = Number(additionalCharge);
    const bataAmount = Number(bata);
    const parkingAmount = Number(parking);
    const interStateTaxAmount = Number(interStateTax);
    const seats = Number(noofSeats);
    // const seasonalPriceAmount = Number(seasonalPrice || 0);

    let totalCost = 0;

    if (localOption?.value === "yes") {
      // Local Trip Calculation
      totalCost =
        (days * baseCharges +
          addKms * addCharges +
          bataAmount +
          parkingAmount) /
        seats;
    } else {
      // Interstate Trip Calculation
      totalCost =
        (days * baseCharges +
          addKms * addCharges +
          bataAmount +
          parkingAmount +
          interStateTaxAmount) /
        seats;
    }

    return totalCost;
  };

  useEffect(() => {
    if (editData && vehicleTypeOptions.length > 0) {
      debugger;
      const vehicleType = vehicleTypeOptions.find(
        (option) => option.label === editData.vehicaltype
      );
      setVehicleTypeOption(vehicleType);

      const acTypeOption = localOptions.find(
        (option) => option.label === editData.actype
      );
      setAcOption(acTypeOption);

      const localTypeOption = localOptions.find(
        (option) => option.label === editData.localinr
      );
      setLocalOption(localTypeOption);

      if (editData.seasonstart) setStartDate(new Date(editData.seasonstart));
      if (editData.seasonend) setEndDate(new Date(editData.seasonend));
      setShowSeasonalFields(!!editData.seasonalprice);
    }
  }, [editData, vehicleTypeOptions]);

  // Add this effect to update total cost when relevant fields change
  useEffect(() => {
    const total = calculateTotalCost();
    setFormValues((prev) => ({
      ...prev,
      pricePerHead: total,
    }));
  }, [
    formValues.baseKM,
    formValues.baseCharge,
    formValues.additionalKM,
    formValues.additionalCharge,
    formValues.bata,
    formValues.parking,
    formValues.interStateTax,
    formValues.noofSeats,
    formValues.seasonalPrice,
    localOption,
  ]);

  return (
    <div>
      {loading ? <Loader /> : <></>}
      <div className="block md:block lg:flex justify-between">
        <div>
          <h2 className="text-primary-black text-[24px] font-semibold">
            {isEdit ? "Edit Transportation" : "Add Transportation"}
          </h2>
          <p className="text-[12px]">
            <span className="font-bold">Master Data</span> /
            <span className="font-regular">
              {isEdit ? "Edit" : "Add"} Vehicle
            </span>
          </p>
        </div>

        <div className="flex mt-4 justify-end gap-4">
          <Button type="primary" label="Save" onClick={handleSave} />
          <Button
            type="secondary"
            label="Cancel"
            onClick={() => navigate("/transportation")}
          />
        </div>
      </div>
      <Paper className="my-8 pt-6 pb-6">
        <h1 className="text-[16px] font-semibold">Upload Type</h1>
        <div className="mt-4 space-y-4">
          <RadioButton
            name="customRadio"
            value="single"
            checked={selectedValue === "single"}
            onChange={handleRadioChange}
            label="Single Upload"
          />
          <RadioButton
            name="customRadio"
            value="bulk"
            checked={selectedValue === "bulk"}
            onChange={handleRadioChange}
            label="Bulk Upload"
            className={"ml-4"}
          />
        </div>
        {selectedValue === "single" && (
          <>
            <Paper className="my-8 border">
              <h1 className="text-[20px] font-bold mb-4">Transport Details</h1>
              <div className="grid grid-flow-row-dense grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                <div>
                  <label className="text-[16px] font-semibold mb-2">
                    Vehicle Type<span className="text-red-500">*</span>
                  </label>
                  <SearchSelect
                    options={vehicleTypeOptions}
                    value={vehicleTypeOption}
                    onChange={handleVehicleTypeChange}
                    placeholder="Select Type..."
                  />
                </div>
                <div>
                  <label className="text-[16px] font-semibold">
                    Vehicle Name<span className="text-red-500">*</span>
                  </label>
                  <InputText
                    value={formValues?.vehicleName}
                    placeholder="Vehicle Name..."
                    name="vehicleName"
                    onChange={handleChange}
                  />
                </div>
                {!isFlightSelected && (
                  <div>
                    <label className="text-[16px] font-semibold mb-2">
                      Ac/ Non AC<span className="text-red-500">*</span>
                    </label>
                    <Select
                      placeholder="Select..."
                      options={localOptions}
                      value={acOption}
                      onChange={handleAcChange}
                    />
                  </div>
                )}

                <div>
                  <label className="text-[16px] font-semibold mb-2">
                    No. of Seats<span className="text-red-500">*</span>
                  </label>
                  <InputText
                    value={formValues?.noofSeats}
                    placeholder="Enter Number..."
                    name="noofSeats"
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label className="text-[16px] font-semibold">
                    City (Source)<span className="text-red-500">*</span>
                  </label>
                  <InputText
                    value={formValues?.city}
                    placeholder="Select City..."
                    name="city"
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label className="text-[16px] font-semibold">
                    Destination<span className="text-red-500">*</span>
                  </label>
                  <InputText
                    value={formValues?.destination}
                    placeholder="Select City..."
                    name="destination"
                    onChange={handleChange}
                  />
                </div>

                {isTrainSelected || isFlightSelected ? null : (
                  <div>
                    <label className="text-[16px] font-semibold">
                      Local<span className="text-red-500">*</span>
                    </label>
                    <Select
                      options={localOptions}
                      value={localOption}
                      onChange={handleLocalChange}
                      placeholder="Select..."
                    />
                  </div>
                )}

                <div>
                  <label className="text-[16px] font-semibold">
                    Base Charge (INR)<span className="text-red-500">*</span>
                  </label>
                  <InputText
                    value={formValues?.baseCharge}
                    placeholder="Enter Amount..."
                    name="baseCharge"
                    onChange={handleChange}
                  />
                </div>
                {/* <div>
                  <label className="text-[16px] font-semibold">
                    Number of Days
                  </label>
                  <InputText
                    value={formValues?.numberOfDays}
                    placeholder="Enter number of days..."
                    name="numberOfDays"
                    onChange={handleChange}
                    type="number"
                  />
                </div>
                 <div>
                  <label className="text-[16px] font-semibold">
                    Number of Students
                  </label>
                  <InputText
                    value={formValues?.numberOfStudents}
                    placeholder="Enter number of students..."
                    name="numberOfStudents"
                    onChange={handleChange}
                    type="number"
                  />
                </div> */}

                {isTrainSelected || isFlightSelected ? null : (
                  <>
                    <div>
                      <label className="text-[16px] font-semibold">
                        Base KM<span className="text-red-500">*</span>
                      </label>
                      <InputText
                        value={formValues?.baseKM}
                        placeholder="Enter..."
                        name="baseKM"
                        onChange={handleChange}
                      />
                    </div>
                    <div>
                      <label className="text-[16px] font-semibold">
                        Additional KM
                      </label>
                      <InputText
                        value={formValues?.additionalKM}
                        placeholder="Enter Amount..."
                        name="additionalKM"
                        onChange={handleChange}
                      />
                    </div>
                    <div>
                      <label className="text-[16px] font-semibold">
                        Additional Charge (INR)
                      </label>
                      <InputText
                        value={formValues?.additionalCharge}
                        placeholder="Enter Amount..."
                        name="additionalCharge"
                        onChange={handleChange}
                      />
                    </div>
                  </>
                )}

                {showInterStateTax && (
                  <div>
                    <label className="text-[16px] font-semibold">
                      Inter State Tax (INR)
                      <span className="text-red-500">*</span>
                    </label>
                    <InputText
                      value={formValues?.interStateTax}
                      placeholder="Enter Amount..."
                      name="interStateTax"
                      onChange={handleChange}
                    />
                  </div>
                )}

                {isTrainSelected || isFlightSelected ? null : (
                  <>
                    <div>
                      <label className="text-[16px] font-semibold">
                        Bata (INR)<span className="text-red-500">*</span>
                      </label>
                      <InputText
                        value={formValues?.bata}
                        placeholder="Enter Amount..."
                        name="bata"
                        onChange={handleChange}
                      />
                    </div>
                    <div>
                      <label className="text-[16px] font-semibold">
                        Parking<span className="text-red-500">*</span>
                      </label>
                      <InputText
                        value={formValues?.parking}
                        placeholder="Enter Amount..."
                        name="parking"
                        onChange={handleChange}
                      />
                    </div>
                  </>
                )}
                <div>
                  <label className="text-[16px] font-semibold">
                    Price Per Head<span className="text-red-500">*</span>
                  </label>
                  <InputText
                    value={formValues?.pricePerHead || ""}
                    placeholder="Enter Amount..."
                    name="pricePerHead"
                    onChange={handleChange}
                  />
                </div>
              </div>
              {/* Seasonal Fields */}
              <div className="col-span-4 flex items-center gap-2 mt-4 mb-4">
                <input
                  type="checkbox"
                  id="seasonalPrice"
                  checked={showSeasonalFields}
                  onChange={(e) => setShowSeasonalFields(e.target.checked)}
                />
                <label
                  htmlFor="seasonalPrice"
                  className="text-[16px] font-semibold "
                >
                  Seasonal Price
                </label>
              </div>
              {showSeasonalFields && (
                <div className="grid grid-flow-row-dense grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                  <div>
                    <label className="text-[16px] font-semibold">
                      Season Start Date<span className="text-red-500">*</span>
                    </label>
                    <DatePicker
                      selectedDate={startDate}
                      onDateChange={(date) =>
                        handleDateChange("startdate", date)
                      }
                      placeholderText="Start Date"
                      className="w-full"
                    />
                  </div>
                  <div>
                    <label className="text-[16px] font-semibold">
                      Season End Date<span className="text-red-500">*</span>
                    </label>
                    <DatePicker
                      selectedDate={endDate}
                      onDateChange={(date) => handleDateChange("enddate", date)}
                      placeholderText="End Date"
                      className="w-full "
                    />
                  </div>
                  <div>
                    <label className="text-[16px] font-semibold">
                      Seasonal Price per Head (INR)
                      <span className="text-red-500">*</span>
                    </label>
                    <InputText
                      value={formValues?.seasonalPrice}
                      placeholder="Enter Seasonal Price..."
                      name="seasonalPrice"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              )}
            </Paper>
          </>
        )}
        {selectedValue === "bulk" && (
          <Paper className="my-8 border">
            <h1 className="text-[20px] font-bold mb-4">Upload File</h1>
            <UploadHotel />
          </Paper>
        )}
      </Paper>
      <ToastContainer />
    </div>
  );
};

export default AddTransportation;
