import React, { useState, useEffect } from "react";
import { post } from "../utils/api";

const Dropdown = ({
  apiUrl,
  payload,
  placeholder,
  onSelect,
  value,
  disabled,
}) => {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(value);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await post(apiUrl, payload);
        if (response && response.data && Array.isArray(response.data)) {
          setOptions(response.data);
        } else {
          console.error("Unexpected API response format:", response);
          setOptions([]); // set an empty array to avoid further issues
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching options:", error);
        setLoading(false);
      }
    };

    fetchOptions();
  }, [apiUrl, payload]);

  useEffect(() => {
    if (value) {
      setSelectedOption(value);
    }
  }, [value]);

  const handleSelect = (event) => {
    const selectedValue = event.target.value;
    const selectedObj = options.find(
      (option) => option.value === selectedValue
    );
    setSelectedOption(selectedValue);
    onSelect(selectedObj); // Pass the full object instead of just the value
  };

  return (
    <div className="relative inline-block w-60 md:w-64">
      {loading ? (
        <div className="flex items-center justify-center">
          <span>Loading...</span>
        </div>
      ) : (
        <select
          value={selectedOption || ""}
          onChange={handleSelect}
          disabled={disabled}
          className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-opacity-50 focus:border-blue-300 focus:ring-blue-200"
        >
          <option value="" disabled>
            {placeholder}
          </option>
          {Array.isArray(options) &&
            options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
        </select>
      )}
    </div>
  );
};

export default Dropdown;
