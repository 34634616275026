import { useEffect, useState } from "react";
import { post } from "../../utils/api";

const useFetchOptions = (type, stateid) => {
  const [options, setOptions] = useState([]);
  // const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const payload = { type, stateid };
        const response = await post("/getOptionList", payload);
        if (response.status) {
          setOptions(response.data);
        } else {
          console.error("Failed to fetch options");
        }
      } catch (error) {
        console.error("Error fetching options:", error);
      }
      // finally {
      //   setLoading(false);
      // }
    };

    fetchOptions();
  }, [type, stateid]);

  return { options };
};

export default useFetchOptions;
