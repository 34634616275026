import React, { useState, useEffect } from "react";

const UploadCSV = ({
  onFileSelect,
  labelText = "Upload",
  fileValidation,
  children,
  initialPreviewUrl = null,
}) => {
  const [error, setError] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(initialPreviewUrl);
  const [uploadedFile, setUploadedFile] = useState(null);

  useEffect(() => {
    if (initialPreviewUrl) {
      setPreviewUrl(initialPreviewUrl);
    }
  }, [initialPreviewUrl]);

  // Validation function to only accept CSV files
  const validateFile = (file) => {
    if (file.type !== "text/csv") {
      return "Only CSV files are allowed.";
    }
    return null;
  };

  const handleFileChange = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      const validationError = fileValidation
        ? fileValidation(file)
        : validateFile(file);
      if (validationError) {
        setError(validationError);
        return;
      }
      setError(null);
      onFileSelect(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        if (file.type === "text/csv") {
          setPreviewUrl("CSV file uploaded");
        }
      };

      if (file.type === "text/csv") {
        reader.readAsText(file);
      }

      setUploadedFile(file);
    }
  };

  const handleRemoveFile = () => {
    setPreviewUrl(null);
    setUploadedFile(null);
  };

  return (
    <div className="flex flex-col items-start lg:items-center">
      <div className="border-2 border-dashed border-gray-300 py-8 md:py-2 px-6 md:px-8 lg:px-12 rounded-lg text-center cursor-pointer relative w-60 h-40 flex items-center justify-center">
        {previewUrl ? (
          <>
            <div className="text-sm">{previewUrl}</div>
            <button
              onClick={handleRemoveFile}
              className="absolute top-0 right-0 mt-1 mr-1 bg-white rounded-full p-1 text-gray-500 hover:text-red-500 focus:outline-none"
            >
              &times;
            </button>
          </>
        ) : (
          <>
            <input
              type="file"
              accept=".csv"
              className="hidden"
              id="file-upload"
              onChange={handleFileChange}
            />
            <label htmlFor="file-upload" className="block">
              <span className="text-[#121212] border-2 border-[#121212] font-semibold text-[12px] sm:text-[16px] md:text-[12px] rounded-lg px-5 py-1">
                {labelText}
              </span>
            </label>
            {children}
          </>
        )}
      </div>
      {error && <p className="text-red-500 mt-2">{error}</p>}
    </div>
  );
};

export default UploadCSV;
